import { verifyPermissionDescription, hasPermissionByDescription } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const integrationsPermission = Vue.extend({
	data() {
		return {
			PERMISSION_IDS,
			permission: '',
			dataIntegrations: '',
		};
	},

	async created() {
		await this.validationAccessIntegrations();
	},

	computed: {
		accessStore() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.accessStore, this.permission?.access);
		},
		importIntegration() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.importIntegration, this.permission?.access);
		},
		requestIntegration() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.requestIntegration, this.permission?.access);
		},
		manageRequest() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageRequest, this.permission?.access);
		},
		manageRequestIntegrations() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageRequestIntegrations, this.permission?.access);
		},
		manageIntegrations() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageIntegrationsStore, this.permission?.access);
		},
		createIntegrations() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.createIntegrations, this.dataIntegrations?.childrens);
		},
		editeIntegrations() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.editeIntegrations, this.dataIntegrations?.childrens);
		},
		deleteIntegrations() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.deleteIntegrations, this.dataIntegrations?.childrens);
		},
		toggleStatusIntegration() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.toggleStatusIntegration, this.dataIntegrations?.childrens);
		},
	},

	methods: {
		async validationAccessIntegrations() {
			this.permission = JSON.parse(WebStorage.getItem('userPermission'));
			this.dataIntegrations = verifyPermissionDescription(this.PERMISSION_IDS?.manageIntegrationsStore, this.permission?.access);

			if (!this.dataIntegrations) {
				this.$router.push({
					name: 'workspace',
				});

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('permission.noPermissionFuncionality'),
					status: 'error',
				});
			}
		},

		canManageIntegrations() {
			return this.manageIntegrations;
		},
		canCreateIntegrations() {
			return this.createIntegrations;
		},
		canEditeIntegrations() {
			return this.editeIntegrations;
		},
		canDeleteIntegrations() {
			return this.deleteIntegrations;
		},
		canToggleStatusIntegration() {
			return this.toggleStatusIntegration;
		},
		canViewDetailsIntegrations() {
			return this.viewDetailsIntegrations;
		},
		canAccessStore() {
			return this.accessStore;
		},
		canManageRequestIntegration() {
			return this.manageRequestIntegrations;
		},
	},
});
