import { hasPermissionByDescription, verifyPermissionDescription } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const credentialPermission = Vue.extend({
	data () {
		return {
			PERMISSION_IDS,
			dataCredential: '',
			dataIntegration: '',
		};
	},

	async created() {
		await this.validationAccessCredentials();
	},

	computed: {
		manageCredentialsPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageCredentialsId, this.dataIntegration?.childrens);
		},

		createCredentialsPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.createCredentialId, this.dataCredential?.childrens);
		},
		editCredentialsPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.editCredentialId, this.dataCredential?.childrens);
		},
		deleteCredentialsPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.deleteCredentialId, this.dataCredential?.childrens);
		},
		shareCredentialsPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.shareCredentialId, this.dataCredential?.childrens);
		},
		showCredentialsPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageCredentialsId, this.dataIntegration?.childrens);
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		async validationAccessCredentials() {
			const permission = JSON.parse(WebStorage.getItem('userPermission'));
			this.dataIntegration = verifyPermissionDescription(this.PERMISSION_IDS?.manageIntegrationId, permission?.access);
			this.dataCredential = verifyPermissionDescription(this.PERMISSION_IDS?.manageCredentialsId, this.dataIntegration?.childrens);

			if (!this.dataIntegration && !this.dataCredential) {
				await AuthService.logout();

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('permission.noPermissionFuncionality'),
					status: 'error',
				});
			}
		},

		canViewDetailCredential() {
			return this.manageCredentialsPermission && this.workspace.ativo;
		},

		canCreateCredential() {
			return this.createCredentialsPermission && this.workspace.ativo;
		},
		canEditCredential(credential) {
			return this.editCredentialsPermission && credential.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canDeleteCredential(credential) {
			return this.deleteCredentialsPermission && credential.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canShareCredential(credential) {
			return this.shareCredentialsPermission && credential.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canViewShareCredential() {
			return this.shareCredentialsPermission && this.workspace.ativo;
		},
	},
});
