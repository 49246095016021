
import BackgroundView from '@/components/BackgroundView.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import { mapActions } from 'vuex';

export default {
	components: {
		BackgroundView,
		ModalButtonSuccess
	},
	name: 'AuditLogsDetail',
	data() {
		return {
			isLoading: false,
			showOpenExecution: false,
			acessDetails: false,

			viewOption: false,

			dataLogsExecution: [],
			dataLogsExecutionDetail: [],
		};
	},
	created() {
	},
	methods: {
	},
};
