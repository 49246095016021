
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import { resourcePermission } from '@/components/mixins/permissions/resource';
import BackgroundView from '@/components/BackgroundView.vue';
import { mainSiderBarPermission } from '@/components/mixins/permissions/sidebar';
import { accessIntegrationPanelPermission } from '@/components/mixins/permissions/panelInfo';
import moment from 'moment';

import SelectTree from '@/components/SelectTree.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import MenuOptions from '@/components/MenuOptions.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import { EXECUTION_QUEUE_RESOURCE_MODAL_KEY } from '../constants';

import Table from '@/components/Table.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

export default mixins(
	restApi,
	resourcePermission,
	mainSiderBarPermission,
	accessIntegrationPanelPermission,
).extend({
	components: {
		MenuOptions,
		ScrollInfinite,
		ModalButtonCancel,
        Table,
        BackgroundView,
        ProgressBar,
		ModalButtonSuccess,
		AlertNotificationModal,
        SelectTree,
	},
	data() {
		return {
			page: 1,
			size: 10,
            serversList: {
                nodes: [],
            },
			scrollInfinite: false,
			isLoadingPage: false,
            alertData: {
                show: false,
            },
            isUpdatingData: false,
            isLoadingAction: false,
            filterByEnvironment: {
            },
            timeToRefresh: {
                id: '1',
                label: '10s'
            },
            EXECUTION_QUEUE_RESOURCE_MODAL_KEY,
            optionsListEnviroment: [],
            optionsListTime: [
                {
					id: 1,
					label: '10s',
				},
				{
					id: 2,
					label: '30s',
				},
				{
					id: 3,
					label: '60s',
				},
            ],
		};
	},
	computed: {
		...mapGetters('ui', {
			isCollapsed: 'sidebarMenuCollapsed',
		}),
		organizationId() {
			return WebStorage.getItem('tenantId');
		},
        updateInterval() {
            return Number(this.timeToRefresh.label.slice(0, -1));
        }

	},
	async created() {
        this.isLoadingPage = true;
        await this.loadEnviroments();
        await this.initResourcePanel();
        this.isLoadingPage = false;

	},
	mounted() {
        this.startDynamicInterval();
	},
	beforeDestroy() {
        this.stopDynamicInterval();
	},
	methods: {
        async loadEnviroments() {
            try {
                const enviroments = await this.restApi().getEnvNamespace();

                const objectList = enviroments.map((string, index) => ({
                    id: index + 1,
                    label: string
                }));
                this.optionsListEnviroment = objectList;
                const matchingEnvironment = objectList.find(env => env.label === window.configuration.namespace);
                this.filterByEnvironment = matchingEnvironment;
            } catch(e) {
                console.error(e);
            }

        },
        openModalExecutions(instancia) {
			this.$store.dispatch('ui/openModalWithData', { modalKey: EXECUTION_QUEUE_RESOURCE_MODAL_KEY, data: {instanceName: instancia.nome}});
        },
        startDynamicInterval() {
            const executeFunction = () => {
                if (!this.isUpdatingData) {
                    this.initResourcePanel();
                } 
                this.intervalId = setTimeout(executeFunction, this.convertSecondsToMilliseconds(this.updateInterval));

            };

            this.intervalId = setTimeout(executeFunction, this.convertSecondsToMilliseconds(this.updateInterval));
        },
        stopDynamicInterval() {
            clearTimeout(this.intervalId);
            this.intervalId = null;
        },
        toggleDropdown(index) {
            this.$set(this.serversList.nodes[index], 'showDropdown', !this.serversList.nodes[index].showDropdown);
        },
        async onSelectEnviroment(type) {
			this.filterByEnvironment = type;
            if (!this.isUpdatingData) await this.initResourcePanel();
		},
        onSelectTime(type) {
			this.timeToRefresh = type;
		},
        async initResourcePanel() {
            this.isUpdatingData = true;
            try {
                let serverListResult = await this.restApi().getServersFromResource({ambiente: this.filterByEnvironment.label});
    
                const showDropdownState = this.serversList?.nodes.reduce((acc, item) => {
                    acc[item.nome] = item.showDropdown || false;
                    return acc;
                }, {});
    
                this.serversList.nodes = serverListResult.nodes.map((item) => ({
                    ...item,
                    showDropdown: showDropdownState[item.nome] || false
                }));
            }  catch (error) {
                this.$store.commit('activeAlert', {
					message: 'Ocorreu um erro ao buscar os dados de monitoramento. Tente novamente mais tarde.',
					status: 'error',
				});
				console.error('error', error);
			}

            this.isUpdatingData = false;
        },
        convertSecondsToMilliseconds(seconds) {
            if (typeof seconds !== 'number' || seconds < 0) {
                return 'Valor inválido';
            }
            return seconds * 1000;
        },
        convertMbToGb(valueInMb) {
            if (typeof valueInMb !== 'number' || valueInMb < 0) {
                return 'Valor inválido';
            }
            return (valueInMb / 1024).toFixed(2) + ' GB';
        },

        convertMilicoreToCore(valueInMilicore) {
            if (typeof valueInMilicore !== 'number' || valueInMilicore < 0) {
                return 'Valor inválido';
            }
            return Math.ceil(valueInMilicore / 1000) + ' Core';
        },
        async restartInstance() {
            this.isLoadingAction = true;


            this.$store.commit('activeAlert', {
                message: "Iniciamos a reinicialização da Instâncias de Serviços - VM's.",
            });
            
            await this.restApi().stopPodExecution(this.alertData.instance.nome).then(() => {
                this.isLoadingAction = false;
                this.alertData = {
                    show: false,
                }
            })

        },
        openModalAlertRestart(instance) {
            this.alertData = {
                show: true,
                instance,
            }
        },

	},
});
