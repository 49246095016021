
import { h } from 'vue';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import TreeFunctionality from '@/components/TreeFunctionality.vue';

import { showMessage } from '@/components/mixins/showMessage';

import Table from '@/components/Table.vue';
import FilterTable from '@/components/FilterTable.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import AliareInput from '@/components/AliareInput.vue';
import InfoBox from '@/components/InfoBox.vue';
import AliareSelect from '@/components/AliareSelect.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import Modal from '@/components/ModalAliare.vue';
import * as XLSX from 'xlsx';


import WebStorage from '@/common/WebStorage';
import { removeItemFromArrayByValue, filter } from '@/components/helpers';
import { userProfilePermission } from '@/components/mixins/permissions/userProfile';

import { ref } from 'vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';


export default mixins(
	nodeHelpers,
	showMessage,
	userProfilePermission,
).extend({
	components: {
		Table,
		FilterTable,
		SelectFilter,
		RoundPhotoFrame,
		InteractionMenu,
		BackgroundView,
		AliareInput,
		AliareSelect,
		AlertNotificationModal,
		ModalButtonSuccess,
		ModalButtonCancel,
		NotificationPage,
		Modal,
		TreeFunctionality,
		InfoBox,
	},
	name: 'CreateProfile',
	data() {
		return {
			page: 1,
			pageSize: 10,
			isLoadingTable: false,
			isLoadingPage: false,
			profileName: '',
			searchFunctionality: '',
			canRegisterProfile: false,
			messageNameError: '',
			currentStep: 'profile',
			scrollInfinite: true,
			filtering: false,
			dataTable: [],
			dataTableTemp: [],
			usersSelected: [],
			partnerId: '',
			offerSolutionId: '',
			subscriptionId: '',
			offerId: '',
			sucessProfile: '',
			showNotification: false,
			showAlert: false,

			dataTableFilteredByName: [],
			dataTableFilteredByEmail: [],
			applyedFilters: [],
			messageNameSuccess: '',
			functionalities: '',
			createProfilePermission: [],
			maxheight: '60vh',
		};
	},
	computed: {
		...mapGetters({
			selectedFunctionalities: "getSelectedFunctionalities",
		}),
		partialUsersSelected() {
			return this.dataTable.length != this.usersSelected.length && this.usersSelected.length > 0;
		},
		allUsersSelected() {
			return this.dataTable.length == this.usersSelected.length;
		},
		alertNotificationDescription() {
			return 'Todas as parametrizações que estão sendo feitas serão <b>excluídas</b> e você <b>não poderá recuperá-las</b>. O que você deseja fazer?';
		},

		firstPageDescriptionProfile() {
			return `O perfil <b>${ this.sucessProfile.nome }</b> foi cadastrado com sucesso`;
		},
		secondPageDescriptionProfile() {
			return `Visualize as informações do perfil cadastrado acessando a tela de <b>detalhamento</b>`;
		},
	},
	methods: {
		...mapActions('aliareAccount', [
			'getProductProfilesByTenantId',
			'getSubscription',
			'getOfferSolution',
			'getUsersByTenantIdV2',
			'getUsersByTenantId',
			'createProductProfile',
			'getGroupPartner',
			'savePermission',
			'linkUsersToPermission',
			'verifyUserActive',
		]),

		goToProfiles() {
			this.$router.push({
				name: 'user-profiles',
			}).catch(()=>{});

			this.showNotification = false;
			this.showAlert = false;
		},

		goToCreateProfile() {
			window.location.reload();
			this.showNotification = false;
		},

		goToProfileDetailing(permissionId) {
			this.$router.push({
				name: 'profileDetailing',
				params: { permissionId },
			}).catch(()=>{});

			this.showNotification = false;
		},

		async verifyUsersActive(data, pushValue) {
			let vm = this;
			let users = JSON.parse(JSON.stringify(data));

			if (!users.length) return;
			let returnProductProfile = true;

			for await (const user of users) {
				await this.verifyUserActive({userId: user.id, returnProductProfile}).then((response) => {
					let indexUser = users.findIndex(userTable => userTable.id === user.id);
					let dataTableIndex = this.dataTable.findIndex(userTable => userTable.id === user.id);

					if (response.active) {
						user.activePermission = true;
						user.profiles = response.profiles;

						this.dataTable.splice(dataTableIndex, 1);
						this.dataTable.splice(dataTableIndex, 0, user);
					} else {
						this.dataTable.splice(dataTableIndex, 1);
					}


				});
			}
		},

		addUserSelected(user) {
			if (this.verifyUserSelected(user)) {
				removeItemFromArrayByValue(this.usersSelected, user.id);
			} else {
				this.usersSelected.push(user.id);
			}
		},

		async selectAllUsers() {
			let selectUsersOnPagination = true;
			if (this.partialUsersSelected || this.allUsersSelected) {
				this.usersSelected = [];
			} else {
				this.dataTable.forEach((user) =>{
					this.addUserSelected(user);
				});
			}
			if (this.scrollInfinite) {
				await this.pagination(selectUsersOnPagination);
			}
		},
		verifyUserSelected(verifyUser) {
			return this.usersSelected.find(id => id === verifyUser.id);
		},
		async getAccount() {
			const payload = { page: 1 };
			await this.getUsersByTenantId(payload).then((response) => {
				this.dataTable = response.data;
				this.dataTableTemp = this.dataTable;

				if (response.length < 25) {
					this.scrollInfinite = false;
				}

				this.verifyUsersActive(this.dataTable);
			});
		},
		async filterTable(data) {
			const dataFilter = {
				currentFilter: data.currentFilter,
				prevSearch:  data.filterSearch || this.dataTableFilteredByName,
				usersEmail:  data.filterEmail || this.dataTableFilteredByEmail,
				applyedFilters: this.applyedFilters,

				getData: async(data) => {
					return this.getUsers(data);
				},
			};

			const resultDataFilter = await filter(dataFilter);

			this.applyFilter(dataFilter.currentFilter, resultDataFilter.dataFiltered);
			return {
				success: true,
				dataFilter,
				dataFiltered: resultDataFilter.dataFiltered,
			};
		},
		async filterByUserName(dataFilter) {
			this.isLoadingPage = true;
			const statusFilter = await this.filterTable({currentFilter: 'filterByUserName', filterSearch: dataFilter});
			this.isLoadingPage = false;

			if (!statusFilter?.dataFiltered?.length) {
				removeItemFromArrayByValue(this.applyedFilters, 'filterByUserName');
			} else if (statusFilter.success) {
				this.dataTableFilteredByName = dataFilter;
			}
		},
		async filterByUserEmail(dataFilter) {
			this.isLoadingTable = true;
			const statusFilter = await this.filterTable({currentFilter: 'filterByUserEmail', filterEmail: dataFilter});
			this.isLoadingTable = false;

			if (!statusFilter?.dataFiltered.length) {
				removeItemFromArrayByValue(this.applyedFilters, 'filterByUserEmail');
			} else if (statusFilter.success) {
				this.dataTableFilteredByEmail = dataFilter;
			}
		},
		async applyFilter(currentFilter, dataFiltered) {
			this.filtering = true;
			this.dataTable = dataFiltered.data;

			if (!this.applyedFilters.includes(currentFilter)) {
				this.applyedFilters.push(currentFilter);
			}
		},
		clearFilter() {
			this.filtering = false;
			this.dataTable = this.dataTableTemp;
			this.applyedFilters = [];
		},
		async pagination(selectUsers) {
			this.page++;
			const payload = { page: this.page };
			await this.getUsersByTenantId(payload).then((response) => {
				for (const index in response?.data) {
					this.dataTable.push(response?.data[index]);

					if (!this.verifyUserSelected(response?.data[index]) && this.selectUsers) {
						this.addUserSelected(response?.data[index]);
					}
				}

				if (response?.data.length < 25) {
					this.scrollInfinite = false;
				}

				this.verifyUsersActive(this.response.data);

			});
			if (selectUsers && this.scrollInfinite) {
				await this.pagination(true);
			}
		},

		async verifyNameProfile() {
			let canRegister = true;

			if(this.profileName != '') {
				const savedProfile = await this.getProductProfilesByTenantId({search: this.profileName}).then((response) => {
					if (response) {
						response.forEach((item) => {
							if (item.description == this.profileName) {
								canRegister = false;
								return;
							}
						});
					}

					if (!response || canRegister) {
						this.messageNameSuccess = this.$locale.baseText('createProfile.validName');
						this.messageNameError = '';
						this.canRegisterProfile = true;
					} else {
						this.messageNameError = this.$locale.baseText('createProfile.invalidName');
						this.messageNameSuccess = '';
						this.canRegisterProfile = false;
					}
				});
			} else {
				this.messageNameSuccess = '';
				this.messageNameError = '';
				this.canRegisterProfile = false;
			}
		},

		changeStep(step) {
			this.currentStep = step;
		},

		async createProfile() {
			this.isLoadingPage = true;
			try {
				const createdProfile = await this.createProductProfile({
					description: this.profileName,
				});
				const savedPermission = await this.savePermission({
					productProfileId: createdProfile.id,
					groupPartnerId: this.partnerId,
					functionalitiesIds: this.selectedFunctionalities,
					offerId: this.offerId,
				});

				if (this.usersSelected) {
					await this.linkUsersToPermission({ subscriptionFunctionalities: this.selectedFunctionalities , subscriptionId: this.subscriptionId, users: this.usersSelected, permissionId: savedPermission.id });
				}

				this.sucessProfile = { nome: this.profileName, permissionId: savedPermission.id };
				this.showNotification = true;

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error?.notifications[0],
					status: 'error',
				});
			}
			this.isLoadingPage = false;
		},
	},
	mounted () {
	},
	async created() {
		this.isLoadingPage = true;
		await this.getGroupPartner().then(response => {
			this.partnerId = response[0].partnerId;
		}),
		await this.getSubscription().then(response => {
			this.offerSolutionId = response.userControls[0].offerSolutionId;
			this.offerId = response.offerId;
			this.subscriptionId = response.solution.id;
		});
		await Promise.all([

			this.getAccount(),
			this.getOfferSolution({ offerSolutionId: this.offerSolutionId}).then(response => {
				this.functionalities = response.offeredFunctionalities;
			}),
		]);

		this.isLoadingPage = false;
	},
});
