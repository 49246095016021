
import { h } from 'vue';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import TreeFunctionality from '@/components/TreeFunctionality.vue';

import { showMessage } from '@/components/mixins/showMessage';

import Table from '@/components/Table.vue';
import FilterTable from '@/components/FilterTable.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import AliareInput from '@/components/AliareInput.vue';
import InfoBox from '@/components/InfoBox.vue';
import AliareSelect from '@/components/AliareSelect.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import Modal from '@/components/ModalAliare.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import * as XLSX from 'xlsx';


import WebStorage from '@/common/WebStorage';
import { PERMISSION_IDS } from '@/constants';
import { removeItemFromArrayByValue, filter } from '@/components/helpers';
import { userProfilePermission } from '@/components/mixins/permissions/userProfile';

import { ref } from 'vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';

import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import TokenService from '@/services/token.service';

export default mixins(
	nodeHelpers,
	showMessage,
	userProfilePermission,
).extend({
	components: {
		Table,
		FilterTable,
		SelectFilter,
		RoundPhotoFrame,
		InteractionMenu,
		BackgroundView,
		NotificationPage,
		AlertNotificationModal,
		AliareInput,
		AliareSelect,
		ModalButtonSuccess,
		ModalButtonCancel,
		Modal,
		TreeFunctionality,
		InfoBox,
	},
	name: 'CreateProfile',
	data() {
		return {
			page: 1,
			pageSize: 10,
			isLoadingTable: false,
			isLoadingPage: false,
			profileName: '',
			searchFunctionality: '',
			messageNameError: '',
			currentStep: 'profile',
			scrollInfinite: true,
			filtering: false,
			dataTable: [],
			dataTableTemp: [],
			usersSelected: [],
			usersBlocked: [],
			prevUsersSelected: [],
			usersDeselected: [],
			partnerId: '',
			offerSolutionId: '',
			subscriptionId: '',
			offerId: '',
			currentProfile: '',
			canEditProfile: true,
			sucessEdit: '',
			showNotification: false,
			showAlert: false,


			dataTableFilteredByName: [],
			dataTableFilteredByEmail: [],
			applyedFilters: [],
			messageNameSuccess: '',
			functionalities: '',
			createProfilePermission: [],
			maxheight: '60vh',
			permissionIds: PERMISSION_IDS,
		};
	},
	computed: {
		...mapGetters({
			selectedFunctionalities: "getSelectedFunctionalities",
		}),
		partialUsersSelected() {
			return this.dataTable.length != this.usersSelected.length && this.usersSelected.length > 0;
		},
		allUsersSelected() {
			return this.dataTable.length == this.usersSelected.length;
		},

		notificationFirstText() {
			return `O perfil <b>${ this.sucessEdit.nome }</b>, foi editado com sucesso.`;
		},
		alertNotificationDescription() {
			return 'Todas as parametrizações que estão sendo feitas serão <b>excluídas</b> e você <b>não poderá recuperá-las</b>. O que você deseja fazer?';
		},
	},
	methods: {
		...mapActions('aliareAccount', [
			'getProductProfilesByTenantId',
			'putProductProfileDescription',
			'getProfileDetailingById',
			'getSubscription',
			'getOfferSolution',
			'getUsersByTenantId',
			'getGroupPartner',
			'putPermission',
			'verifyUserActive',
			'getUserPermissionById',
			'blockUserInPermission',
			'unblockUserInPermission',
		]),
		goToProfiles() {
			this.$router.push({
				name: 'user-profiles',
			}).catch(()=>{});

			this.showNotification = false;
			this.showAlert = false;
		},

		goToProfileDetailing(permissionId) {
			this.$router.push({
				name: 'profileDetailing',
				params: { permissionId },
			}).catch(()=>{});

			this.showNotification = false;
		},

		async verifyUsersActive(data, pushValue) {
			let vm = this;
			let users = JSON.parse(JSON.stringify(data));

			if (!users.length) return;
			let returnProductProfile = true;

			for await (const user of users) {
				await this.verifyUserActive({userId: user.id, returnProductProfile}).then((response) => {
					let indexUser = users.findIndex(userTable => userTable.id === user.id);
					let dataTableIndex = this.dataTable.findIndex(userTable => userTable.id === user.id);

					if (response.active) {
						user.activePermission = true;
						user.profiles = response.profiles;

						this.dataTable.splice(dataTableIndex, 1);
						this.dataTable.splice(dataTableIndex, 0, user);
					} else {
						this.dataTable.splice(dataTableIndex, 1);
					}


				});
			}
		},

		addUserSelected(user) {
			if (this.verifyUserSelected(user)) {
				removeItemFromArrayByValue(this.usersSelected, user.id);
				if (this.prevUsersSelected.find(id => id === user.id)) {
					this.usersDeselected.push(user.id);
				}

			} else {
				this.usersSelected.push(user.id);

				if (this.prevUsersSelected.find(id => id === user.id)) {
					removeItemFromArrayByValue(this.usersDeselected, user.id);
				}
			}
		},

		async selectAllUsers() {
			let selectUsersOnPagination = true;
			if (this.partialUsersSelected || this.allUsersSelected) {
				this.usersSelected = [];
			} else {
				this.dataTable.forEach((user) =>{
					this.addUserSelected(user);
				});
			}
			if (this.scrollInfinite) {
				await this.pagination(selectUsersOnPagination);
			}
		},
		verifyUserSelected(verifyUser) {
			return this.usersSelected.find(id => id === verifyUser.id);
		},
		async getAccount() {
			const payload = { page: 1 };
			await this.getUsersByTenantId(payload).then((response) => {
				this.dataTable = response.data;
				this.dataTableTemp = this.dataTable;

				if (response.length < 25) {
					this.scrollInfinite = false;
				}

				this.verifyUsersActive(this.dataTable);
			});
		},
		async filterTable(data) {
			const dataFilter = {
				currentFilter: data.currentFilter,
				prevSearch:  data.filterSearch || this.dataTableFilteredByName,
				usersEmail:  data.filterEmail || this.dataTableFilteredByEmail,
				applyedFilters: this.applyedFilters,

				getData: async(data) => {
					return this.getUsers(data);
				},
			};

			const resultDataFilter = await filter(dataFilter);

			this.applyFilter(dataFilter.currentFilter, resultDataFilter.dataFiltered);
			return {
				success: true,
				dataFilter,
				dataFiltered: resultDataFilter.dataFiltered,
			};
		},
		async filterByUserName(dataFilter) {
			this.isLoadingPage = true;
			const statusFilter = await this.filterTable({currentFilter: 'filterByUserName', filterSearch: dataFilter});
			this.isLoadingPage = false;

			if (!statusFilter?.dataFiltered?.length) {
				removeItemFromArrayByValue(this.applyedFilters, 'filterByUserName');
			} else if (statusFilter.success) {
				this.dataTableFilteredByName = dataFilter;
			}
		},
		async filterByUserEmail(dataFilter) {
			this.isLoadingTable = true;
			const statusFilter = await this.filterTable({currentFilter: 'filterByUserEmail', filterEmail: dataFilter});
			this.isLoadingTable = false;

			if (!statusFilter?.dataFiltered.length) {
				removeItemFromArrayByValue(this.applyedFilters, 'filterByUserEmail');
			} else if (statusFilter.success) {
				this.dataTableFilteredByEmail = dataFilter;
			}
		},
		async applyFilter(currentFilter, dataFiltered) {
			this.filtering = true;
			this.dataTable = dataFiltered.data;

			if (!this.applyedFilters.includes(currentFilter)) {
				this.applyedFilters.push(currentFilter);
			}
		},
		clearFilter() {
			this.filtering = false;
			this.dataTable = this.dataTableTemp;
			this.applyedFilters = [];
		},
		async pagination(selectUsers) {
			this.page++;
			const payload = { page: this.page };
			await this.getUsersByTenantId(payload).then((response) => {
				for (const index in response?.data) {
					this.dataTable.push(response?.data[index]);

					if (!this.verifyUserSelected(response?.data[index]) && this.selectUsers) {
						this.addUserSelected(response?.data[index]);
					}
				}

				if (response?.data.length < 25) {
					this.scrollInfinite = false;
				}

				this.verifyUsersActive(this.response.data);

			});
			if (selectUsers && this.scrollInfinite) {
				await this.pagination(true);
			}
		},

		verifyNameProfile:
		_.debounce(async function (e) {
			let canRegister = true;

			if (this.profileName === this.currentProfile.productProfile.description) {
				this.messageNameSuccess = '';
				this.messageNameError = '';
				this.canEditProfile = true;
				return;
			}

			if(this.profileName != '') {
				const savedProfile = await this.getProductProfilesByTenantId({search: this.profileName}).then((response) => {
					if (response) {
						response.forEach((item) => {
							if (item.description == this.profileName) {
								canRegister = false;
								return;
							}
						});
					}

					if (!response || canRegister) {
						this.messageNameSuccess = this.$locale.baseText('createProfile.validName');
						this.messageNameError = '';
						this.canEditProfile = true;
					} else {
						this.messageNameError = this.$locale.baseText('createProfile.invalidName');
						this.messageNameSuccess = '';
						this.canEditProfile = false;
					}
				});
			} else {
				this.messageNameSuccess = '';
				this.messageNameError = '';
				this.canEditProfile = false;
			}
		},
		500),


		changeStep(step) {
			this.currentStep = step;
		},

		async saveChangeProfile() {
			this.isLoadingPage = true;
			const permissionId = this.$route.params.permissionId;
			const description = this.profileName;

			let usersToAddToPermission = this.usersSelected.filter( a => !this.prevUsersSelected.includes( a ) );
			let usersToRemoveFromPermission = this.usersDeselected;

			try {
				const savedPermission = await this.putPermission({
					permissionId,
					functionalitiesIds: this.selectedFunctionalities,
				});

				for(const id of usersToRemoveFromPermission) {
					await this.verifyUserBlockedInPermissionAndRemove(id);
				}

				for(const id of usersToAddToPermission) {
					await this.verifyUserBlockedInPermissionAndAdd(id);
				}

				if(description != this.currentProfile.productProfile.description) {
					const payload = { description };
					await this.putProductProfileDescription({ productProfileId: this.currentProfile.productProfile.id, payload });
				}

				this.sucessEdit = { nome: description, permissionId };
				this.showNotification = true;

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error?.notifications[0],
					status: 'error',
				});
			}
			this.isLoadingPage = false;
		},

		async verifyUserBlockedInPermissionAndRemove(userId) {

			const vm = this;
			await this.getUserPermissionById({ userId }).then(async (permissionUser) => {
				for(const permission of permissionUser) {
					if (vm.currentProfile.productProfile.id == permission.productProfile.id) {
						let idLinkUserToPermission = permission.userProfile.id;

						await this.blockUserInPermission({ userId: idLinkUserToPermission });
					}
				}
			});
		},

		async verifyUserBlockedInPermissionAndAdd(userId) {
			const vm = this;

			if (this.usersBlocked.find((userIdBlocked => userIdBlocked == userId))) {
				await this.getUserPermissionById({ userId }).then(async (permissionUser) => {
					for(const permission of permissionUser) {
						if (vm.currentProfile.productProfile.id == permission.productProfile.id) {
							let idLinkUserToPermission = permission.userProfile.id;

							await this.unblockUserInPermission({ userId: idLinkUserToPermission });
						}
					}
				});
			}
		},
		async loadCurrentProfile() {
			const permissionId = this.$route.params.permissionId;

			await this.getProfileDetailingById({permissionId}).then((profileInfo) => {
				this.profileName = profileInfo.productProfile.description;
				this.currentProfile = profileInfo;

			});

		},

		getUsersSelectedPrev() {
			this.currentProfile.usersProfiles.forEach((user) => {
				if (!user.blocked) {
					this.prevUsersSelected.push(user.userId);
					this.usersSelected.push(user.userId);
				} else {
					this.usersBlocked.push(user.userId);
				}
			});
		},
	},
	async created() {
		this.isLoadingPage = true;
		await this.getGroupPartner().then(response => {
			this.partnerId = response[0].partnerId;
		}),
		await this.getSubscription().then(response => {
			this.offerSolutionId = response.userControls[0].offerSolutionId;
			this.offerId = response.offerId;
			this.subscriptionId = response.solution.id;
		});
		await Promise.all([

			this.getAccount(),
			this.getOfferSolution({ offerSolutionId: this.offerSolutionId}).then(response => {
				this.functionalities = response.offeredFunctionalities;
			}),
			this.loadCurrentProfile(),
		]);

		await this.getUsersSelectedPrev();

		if(!this.canViewEditUserProfile()) {
			this.$store.commit('activeAlert', {
				message: this.$locale.baseText('permission.noPermissionFuncionality'),
				status: 'error',
			});

			this.$router.push({
				name: 'user-profiles',
			});
		}
		this.isLoadingPage = false;
	},
});
