<template>
    <div>

        <main class="header-filter">
            <div class="date-feedback">
                <div class="date-info" v-if="feedbackDate">
                    <h3 class="date-info__date" v-html="feedbackDate"></h3>
                    <span class="date-info__hour" v-if="todaySelected">{{ feedbackHour }}</span>
                </div>
            </div>
            
            <div class="date-filter">
                <div class="date-filter__item" @click="changeDateFilter('today')" :class="{ active: todaySelected }">
                    <i class="dateIcon todayIcon"></i>
                    <span>Hoje</span>
                </div>
                <div class="date-filter__item" @click="changeDateFilter('lastSeven')" :class="{ active: lastSevenSelected }">
                    <i class="dateIcon lastSevenIcon"></i>
                    <span>Últimos 7 dias</span>
                </div>
                <div class="date-filter__item" @click="changeDateFilter('lastThirty')" :class="{ active: lastThirtySelected }">
                    <i class="dateIcon lastThirtyIcon"></i>
                    <span>Últimos 30 dias</span>
                </div>
                <div class="date-filter__item" @click="changeDateFilter('lastSixty')" :class="{ active: lastSixtySelected }">
                    <i class="dateIcon lastSixtyIcon"></i>
                    <span>Últimos 60 dias</span>
                </div>
            </div>
            <hr class="separator">
            <div class="container-btn">
                <ModalButtonSuccess
                    :titleButton="$locale.baseText('Abrir Filtros')"
                    width="138"
                    @click="openFilters()"
                    alwaysActive
                    filterIcon
                />
            </div>
        </main>
        <div class="applyedFilters" v-if="hasFilterApplyed">
            <h4 class="applyedFilters--title">Filtros aplicados</h4>
            <section class="applyedFilters--filters">
                <div v-if="dataFilter.modeDate == 'interval'" class="applyedFilters--item">
                    {{ dataFilter.selectedOrganizationIds.length }} Organizações <i class="removeIcon" @click="removeFilter('organization')"></i>
                </div>
                <div v-if="dataFilter.selectedOrganizationIds[0] !== 'null'" class="applyedFilters--item">
                    {{ dataFilter.selectedOrganizationIds.length }} Organizações <i class="removeIcon" @click="removeFilter('organization')"></i>
                </div>
                <div v-if="dataFilter.selectedWorkspaceIds[0] !== 'null'" class="applyedFilters--item">
                    {{ dataFilter.selectedWorkspaceIds.length }} Espaços de Trabalho <i class="removeIcon" @click="removeFilter('workspace')"></i>
                </div>
                <div v-if="dataFilter.selectedProjectIds[0] !== 'null'" class="applyedFilters--item">
                    {{ dataFilter.selectedProjectIds.length }} Projetos <i class="removeIcon" @click="removeFilter('project')"></i>
                </div>
                <div v-if="dataFilter.selectedFlowIds[0] !== 'null'" class="applyedFilters--item">
                    {{ dataFilter.selectedFlowIds.length }} Fluxos <i class="removeIcon" @click="removeFilter('workflow')"></i>
                </div>

                <div v-if="dataFilter.resultExecutionFilter[0] !== 'null'" v-for="result in dataFilter.resultExecutionFilter" :key="result" class="applyedFilters--item">
                     Status: {{ result }} <i class="removeIcon" @click="removeFilter('result', result)"></i>
                </div>

                <div class="applyedFilters--reset" @click="resetFilter()">
                    <i class="resetIcon"></i> Redefinir Filtros
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import { FILTER_PANEL_MODAL_KEY } from '../constants';

moment.locale('pt')
moment.updateLocale('pt', {
    months : [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
        "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ]
});
export default {
    name: 'HeaderFilterIntegration',
    data() {
        return {
        };
    },
    components: {
		ModalButtonSuccess,
        FILTER_PANEL_MODAL_KEY,
	},
  computed: {
    hasFilterApplyed() {
        return (
            this.dataFilter.selectedOrganizationIds[0] !== 'null' ||
            this.dataFilter.selectedWorkspaceIds[0] !== 'null' ||
            this.dataFilter.selectedProjectIds[0] !== 'null' ||
            this.dataFilter.selectedFlowIds[0] !== 'null' ||
            this.dataFilter.resultExecutionFilter[0] !== 'null'
        );
    },
    dataFilter() {
        return this.$store.getters.dataFilterPanel;
    },
    dateFilterSelected() {
        return this.$store.getters.dataFilterPanel.date.dateString;
    },
    todaySelected() {
        if (this.dataFilter.date.modeDate == 'interval') return;

        if (this.dateFilterSelected == 'today') {
            return true
        } else {
            return
        }
    },
    lastSevenSelected() {
        if (this.dataFilter.date.modeDate == 'interval') return;

        if (this.dateFilterSelected == 'lastSeven') {
            return true
        } else {
            return
        }
    },
    lastThirtySelected() {
        if (this.dataFilter.date.modeDate == 'interval') return;

        if (this.dateFilterSelected == 'lastThirty') {
            return true
        } else {
            return
        }
    },
    lastSixtySelected() {
        if (this.dataFilter.date.modeDate == 'interval') return;

        if (this.dateFilterSelected == 'lastSixty') {
            return true
        } else {
            return
        }
    },
    feedbackDate() {
        let localDate, endDate;

        if (this.dataFilter.date.modeDate == 'interval') return;

        if (this.todaySelected) {
            localDate = moment().local().format('DD [de] MMMM [de] YYYY');
        } else if (this.lastSevenSelected) {
            localDate = moment().local().format('DD [de] MMMM [de] YYYY HH[h]');
            endDate = moment().subtract(7, 'days').startOf('day').format('DD [de] MMMM [de] YYYY HH[h]');
        } else if (this.lastThirtySelected) {
            localDate = moment().local().format('DD [de] MMMM [de] YYYY HH[h]');
            endDate = moment().subtract(30, 'days').startOf('day').format('DD [de] MMMM [de] YYYY HH[h]');
        } else if (this.lastSixtySelected) {
            localDate = moment().local().format('DD [de] MMMM [de] YYYY HH[h]');
            endDate = moment().subtract(60, 'days').startOf('day').format('DD [de] MMMM [de] YYYY HH[h]');
        } else {
            return
        }
        return this.todaySelected ? localDate : 'de ' + endDate + '<hr>' + 'até ' + localDate;
    },
    feedbackHour() {
        let localHour
        if (this.todaySelected) {
            localHour = '0h às 24h';
        } else if (this.lastSevenSelected) {
            localHour = '0h às 24h';
        } else if (this.lastThirtySelected) {
            localHour = '0h às 24h';
        } else if (this.lastSixtySelected) {
            localHour = '0h às 24h';
        }
        return localHour
    }
  },
  watch: {
    
  },
  created() {
  
  },
  methods: {
    translateStatus(palavra) {
        const traducoes = {
            "Falha": "error",
            "Sucesso": "success",
            "Alerta": "alert",
            "Não Armazenado": "unknown/crashed",
            "Interrompido": "canceled",
            "Ignorado": "ignored",

        };

        return traducoes[palavra] || "undefined";
    },
    removeFilter(type, data) {
        let activeFilter = this.$store.getters.dataFilterPanel;
        switch (type) {
            case 'organization':
                this.$store.commit('setDataFilterPanel', {
                    ...activeFilter,
                    selectedOrganizationIds: ['null'],
                });
                break;
            case 'workspace':
                this.$store.commit('setDataFilterPanel', {
                    ...activeFilter,
                    selectedWorkspaceIds: ['null'],
                });
                break;
            case 'project':
                this.$store.commit('setDataFilterPanel', {
                    ...activeFilter,
                    selectedProjectIds: ['null'],
                });
                break;
            case 'workflow':
                this.$store.commit('setDataFilterPanel', {
                    ...activeFilter,
                    selectedFlowIds: ['null'],
                });
                break;
            case 'result':
                this.$store.commit('setDataFilterPanel', {
                    ...activeFilter,
                    resultExecutionFilter: this.removeStatus(this.dataFilter.resultExecutionFilter, data),
                    translateResultExecutionFilter: this.removeStatus(this.dataFilter.translateResultExecutionFilter, this.translateStatus(data))
                });
                break;
        }

        this.$root.$emit("applyFiltersIntegrationPanel");

    },
    removeStatus(statusArray, value) {
        const resultado = statusArray.filter(status => status !== value);
        return resultado.length > 0 ? resultado : ['null'];
    },
    resetFilter() {
        this.$store.commit('setDataFilterPanel', {
            date: {
                dateString: 'today',
                dateStart: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
                dateEnd: moment().endOf('day').format('YYYY-MM-DD HH:mm'),
            },
            selectedOrganizationIds: ['null'],
            selectedWorkspaceIds: ['null'],
            selectedProjectIds: ['null'],
            selectedFlowIds: ['null'],
            resultExecutionFilter: ['null'],
            translateResultExecutionFilter: ['null'],
        });

        this.$root.$emit("applyFiltersIntegrationPanel");
    },
    async changeDateFilter(dateString) {
        const activeFilter = this.$store.getters.dataFilterPanel;
        let dateStart;
        let dateEnd;
         if (dateString == 'today') {
            dateStart =  moment().startOf('day').format('YYYY-MM-DD HH:mm');
            dateEnd = moment().endOf('day').format('YYYY-MM-DD HH:mm');
        } else if (dateString == 'lastSeven') {
            dateEnd = moment().local().format('YYYY-MM-DD HH:mm');
            dateStart = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
        } else if (dateString == 'lastThirty') { 
            dateEnd = moment().local().format('YYYY-MM-DD HH:mm');
            dateStart = moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
        } else if (dateString == 'lastSixty') {
            dateEnd = moment().local().format('YYYY-MM-DD HH:mm');
            dateStart = moment().subtract(60, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
        }

        await this.$store.commit('setDataFilterPanel', {
            ...activeFilter,
            date: {
                dateString,
                dateStart,
                dateEnd,
                modeDate: 'dateOption',
            }
        });
        this.$root.$emit("applyFiltersIntegrationPanel");
    },
    openFilters() {
        this.$store.dispatch('ui/openModal', FILTER_PANEL_MODAL_KEY);
    },

   
  },
  watch: {

  },
};
</script>



<style scoped lang="scss">
h1, h2, h3, h4, p {
	margin: 0;
}
.removeIcon {
    background-image: url('../assets/closeIcon.svg');
    background-repeat: no-repeat;
    height: 10px;
    width: 10px;
    background-size: 100% 100%;
    position: relative;
    top: 2px;
    cursor: pointer;
}
.header-filter, .applyedFilters {
    padding: 16px;
    background: #1c2638;
	display: flex;
    align-items: center;
    border: 1px solid #34476A;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    &:before, &:after {
        pointer-events: none;
        width: 150px;
        content: '';
        height: 150px;
        position: absolute;
    }

    &:before {
        left: -60px;
        top: -66px;

        background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(0, 195, 221, 0.4) 0%,
            rgba(22, 30, 44, 0) 100%
        );
    }

    &:after {
        right: -63px;
        top: 9px;
        background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(89, 214, 76, 0.32) 0%,
            rgba(22, 30, 44, 0) 100%
        );
    }

	.date-info, .date-filter{
		display: flex;
	}
    .date-info {
        margin-top: -6px;
        flex-direction: column;
        row-gap: 6px;

        * { color: #FFF; }
        

        &__date {
            font-size: 20px;
            font-weight: 300;
        }
        &__hour {
            font-size: 14px;
        }
    }

    .date-filter {
        margin: 0 10px 0 auto;
        

        &__item {
            column-gap: 8px;
            display: flex;
            align-items: center;
            background: #223049;
            padding: 8px 16px;
            outline: 1px solid #4A638F;
            color: #FFFFFFA3;
            font-size: 14px;
            margin-left: 1px;
            cursor: pointer;

            &:hover {
                color: #00C3DD; 
            }

            &.active {
                color: #00C3DD; 
                border: 1px solid #00C3DD;

                .dateIcon {
                    filter: invert(53%) sepia(77%) saturate(754%) hue-rotate(143deg) brightness(124%) contrast(103%);
                }
            }
            

            &:first-child {
                border-radius: 3px 0 0 3px;
            }
            &:last-child {
                border-radius: 0 3px 3px 0;
            }
            .dateIcon {
                width: 24px;
                height: 24px;
                display: inline-block;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            .todayIcon {
                background-image: url('../assets/calendarSimpleIcon.svg');
            }
            .lastSevenIcon {
                background-image: url('../assets/calendarSevenIcon.svg');
            }
            .lastThirtyIcon {
                background-image: url('../assets/calendarLastThirty.svg'); 
            }
            .lastSixtyIcon {
                background-image: url('../assets/calendarLastSixty.svg'); 
            }
            
        }
    }
    .container-btn {
        margin-left: 10px;
        z-index: 9;
    }
}
.applyedFilters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;

    * { color: #FFF; }


    &--title {
        font-size: 18px;
    }
    &--filters {
        column-gap: 6px;
        width: 100%;
        display: flex;
    }
    &--item {
        display: flex;
        align-items: center;
        column-gap: 6px;
        padding: 0px 6px;
        border: 1px solid #34476A;
        border-radius: 12px;
        font-size: 14px;
    }
    &--reset {
        padding-left: 24px;
        border-left: 1px solid #34476A;
        margin-left: auto;
        color: #ff495f;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        column-gap: 12px;

        .resetIcon {
            background-image: url('../assets/clearRed.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 18px;
            width: 14px;
        }
	}
    &:before, &::after {
        display: none;
    }
}
</style>
