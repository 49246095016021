
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';

import { removeItemFromArrayByValue } from '@/components/helpers';
import Vue from 'vue';

import BackgroundView from '@/components/BackgroundView.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import Table from '@/components/Table.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import ShareUserFilter from '@/components/ShareUserFilter.vue';
import QuantityFilter from '@/components/QuantityFilter2.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import { ICredentialsResponse } from '@/Interface';
import { truncate } from '@/components/helpers';
import { equivalencePermission } from '@/components/mixins/permissions/equivalences';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import { genericHelpers } from '@/components/mixins/genericHelpers';

import FilterIcon from '@/components/FilterIcon.vue';
import FilterBar from '@/components/FilterBar.vue';
import { restApi } from '@/components/mixins/restApi';

import FilterTable from '@/components/FilterTable.vue';

import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import WebStorage from '@/common/WebStorage';

import { mapGetters} from 'vuex';
import { previousRoute } from '../router/index.ts';

import {
	CREDENTIAL_SELECT_MODAL_KEY,
	CREATE_EQUIVALENCE_OPTIONS_MODAL,
} from '@/constants';


export default mixins(
	restApi,
	nodeHelpers,
	showMessage,
	equivalencePermission,
	genericHelpers,
).extend({
	components: {
		AlertNotificationModal,
		BackgroundView,
		InteractionMenu,
		Table,
		RoundPhotoFrame,
		ShareUserFilter,
		QuantityFilter,
		FilterTable,
		FilterIcon,
		FilterBar,
		ModalButtonSuccess,
		ModalButtonCancel,
	},
	name: 'EquivalencesList',
	data() {
		return {
			value: '',
			showAlert: false,
			maxheight: '60vh',
			activeEquivalence: '',
			renderComponent: true,
			interactionMenu__link: '',
			interactionMenu__headerDropdownPlaceHolder:
				'credentials.interactionMenu__headerDropdownPlaceHolder',
			interactionMenu__headerPlaceHolderSearch:
				'credentials.interactionMenu__headerPlaceHolderSearch',
			interactionMenu__buttonToCleanFilter: 'credentials.interactionMenu__buttonToCleanFilter',
			tableTitle__3: 'credentials.tableTitle__updateDates',
			tableTitle__4: 'credentials.tableTitle__sharing',
			tableTitle__5: 'credentials.tableTitle__actions',

			credentials__tableActionsButtonLeftDropdown:
				'credentials.credentials__tableActionsButtonLeftDropdown',
			credentials__tableActionsButtonLeftDropdownEdit:
				'equivalences.editEquivalence.title',
			credentials__tableActionsButtonLeftDropdownDelete:
				'credentials.credentials__tableActionsButtonLeftDropdownDelete',
			credentials__modalCreateTitle: 'credentials.credentials__modalCreateTitle',
			credentials__modalCreateAlert: 'credentials.credentials__modalCreateAlert',
			equivalencesList: [],
			equivalencesListTemp: [],
			scrollInfinite: true,
			page: 1,
			pageSize: 10,
			numCells: 5,
			validation: true,
			isLoadingTable: false,
			isLoadingPage: false,
			filtering: false,
			organizationInfo: '',
			isLoadingAction: false,


			arrayEquivalencesSelected: [],
			dataEquivalence: [],

			queryQtd: {
				qntMin: this.$route.query?.qtdMinimo || '',
				qntMax: this.$route.query?.qtdMaximo || '',
			},

			queryUser: typeof this.$route.query?.usuariosIdsCompartilhado == 'string' ? [this.$route.query?.usuariosIdsCompartilhado] : this.$route.query?.usuariosIdsCompartilhado || [],


			filtersActive: {},
			previousRoute: previousRoute,
			clearfiltering: false,
			numPicture: 3,
		};
	},
	async mounted() {
		this.$root.$on('reloadList', () => {
			this.loadTable();
		});
		this.$root.$on('cancelSwitchMode', () => {
			this.equivalencesList.forEach((row) => {
				row.selectedEquivalence = false;
			});
			this.arrayEquivalencesSelected = [];
			this.forceRerender();
		});
		this.updateNumCells();
		window.addEventListener('resize', this.updateNumCells);
	},
	async created() {
		this.isLoadingPage = true;
		await this.verifyPermissionUserAndInitiate();
		this.organizationInfo = JSON.parse(WebStorage.getItem('organizationData'));
		this.isLoadingPage = false;
	},
	computed: {
		...mapGetters('ui', {
			isCollapsed: 'sidebarMenuCollapsed',
		}),

		allEquivalencesSelected() {
			return this.equivalencesAbleToSelect == this.arrayEquivalencesSelected.length && this.arrayEquivalencesSelected.length > 0;
		},
		equivalencesAbleToSelect() {
			return this.equivalencesList.filter(equivalence => equivalence.permissao == 'Editar').length;
		},
		partialEquivalencesSelected() {
			return this.equivalencesAbleToSelect != this.arrayEquivalencesSelected.length && this.arrayEquivalencesSelected.length > 0;
		},

		alertNotificationTitle() {
			return 'Exclusão da Equivalência';
		},
		alertNotificationDescription() {

			return `Excluir a equivalência <b>${truncate(this.activeEquivalence.nome)}</b> apagará todas as parametrizações feitas e os registros adicionados, você não poderá recuperá-los. O que deseja fazer?`;
		},

		intervalQtdText() {
			if (this.queryQtd.qntMin == '' && this.queryQtd.qntMax == '') {
					return 'Quantidade';
			}

			const inicioFormatado = this.queryQtd.qntMin ? this.queryQtd.qntMin : '';
			const fimFormatado = this.queryQtd.qntMax ? this.queryQtd.qntMax : '';
			return `Qnt. Mínima: ${inicioFormatado}\nQnt. Máxima: ${fimFormatado}`;
		},

		shareUserText() {
      if (!this.queryUser.length) {
        return 'Compartilhando';
      }
    },
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		emitSelectAll() {
			this.isLoadingTable = true;
			if (this.allEquivalencesSelected) {
				this.equivalencesList.forEach((form) => {
					form.selectedEquivalence = false;
				});
				this.arrayEquivalencesSelected = [];
				this.isLoadingTable = false;
				this.$root.$emit('deselectAll');
			} else {
				this.loadTableEquivalences({sizeInfinite: true});
			}
		},
		toggleSelectedEquivalence(row) {
			if (row.permissao != 'Editar') return;

			this.$root.$emit('addOrRemoveItemSelected', JSON.parse(JSON.stringify({
				...row,
				canShare: this.canShareEquivalence(row),
			})));

			row.selectedEquivalence = !row?.selectedEquivalence;
			if (row.selectedEquivalence) {
				this.arrayEquivalencesSelected.push(row);
			} else {
				let index = this.arrayEquivalencesSelected.map(items => {
					return items.id;
				}).indexOf(row.id);

				this.arrayEquivalencesSelected.splice(index, 1);
			}
			this.$root.$emit('switchSelectMode', {active: true, object: 'Equivalencia'});
			this.forceRerender();
		},
		openModalDelete(equivalence) {
			this.activeEquivalence = equivalence;
			this.showAlert = true;
		},
		async forceRerender() {
			this.renderComponent = false;

			await Vue.nextTick();

			this.renderComponent = true;
    },
		async verifyPermissionUserAndInitiate() {
			await this.validationAccessEquivalences().then(async () => {
				if (this.showEquivalencesPermission) {
					await this.initPageEquivalence();
				} else {
					this.$store.commit('setData403', {show: true, prevPage: previousRoute});
				}
			});
		},
		async initPageEquivalence() {
			this.isLoadingPage = true;
			const applyPage = WebStorage.getItem('applyPage');
			if (this.previousRoute.name == 'equivalencesDetailing' && applyPage == 'equivalence' ) {
				
				this.$router.replace({query: { ...this.$store.getters?.filters } }).catch(() => {});

				this.loadPlaceholders(this.$route.query);
			};

			if(Object.keys(this.$route.query).length) {
				this.filterTable();
			} else {
				await this.loadTableEquivalences();
			}
			this.isLoadingPage = false;
		},
		loadPlaceholders(query) {
			this.queryQtd = {
				qntMin: query?.qtdMinimo ?? '',
				qntMax: query?.qtdMaximo ?? '',
			};
			this.queryUser =  typeof query?.usuariosIdsCompartilhado == 'string' ? [query?.usuariosIdsCompartilhado] : query?.usuariosIdsCompartilhado || [];
		},
		limitedUsersShareFilter(listUsers) {
			return listUsers.slice(0, this.numPicture);
		},
		async filterBySearch(search) {
			this.filtering = search.length <= 2 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length <= 2 ? '' : search);
		},
		async filterByQtd(min, max) {
			if (min !== '' || max !== '') {
				this.queryQtd = {
					qntMin: min,
					qntMax: max,
				};

        this.filtering = (this.queryQtd.qntMin === '' && this.queryQtd.qntMax === '');
        this.addOrRemoveFilter(this.filtersActive, 'filterQtd', this.queryQtd);
    	} else {
				this.queryQtd = {
					qntMin: '',
					qntMax: '',
				};
			}
		},
		async filterByUser(dataFilter) {
			const userIds = dataFilter.map(item => item.id);
			this.queryUser = dataFilter;
			this.addOrRemoveFilter(this.filtersActive, 'filterByUser', userIds);
			this.filtering = !this.queryUser.length;
		},

		async filterTable(filters) {
			try {
				this.isLoadingTable = true;

				const routeQuery = this.$route.query;
				const payload = {
					qtdMinimo:  filters?.filterQtd?.qntMin || routeQuery?.qtdMinimo,
					qtdMaximo:  filters?.filterQtd?.qntMax || routeQuery?.qtdMaximo,
					searchNome: filters?.filterSearch || routeQuery?.searchNome,
					usuariosIdsCompartilhado: filters?.filterByUser || routeQuery?.usuariosIdsCompartilhado,
				};
				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.restApi().getEquivalences(this.workspace.id, payload);
				await this.applyFilter(dataFilter, payload);

			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoadingTable = false;
			}
		},
		async applyFilter(dataFiltered, query) {
			this.filtering = true;
			this.clearfiltering = true;
			this.scrollInfinite = true;
			this.equivalencesList = dataFiltered;

			this.$store.commit('setFilters', query);
			WebStorage.setItem('applyPage', 'equivalence');
		},
		async clearFilter() {

			this.page = 1;
			this.filtering = false;
			this.clearfiltering = false;
			this.filtersActive = {};
			this.queryUser = [];
			this.scrollInfinite = true;
			this.queryQtd = {
				qntMin: '',
				qntMax: '',
			};

			await this.$router.replace({'query': null}).catch(() => {});

			this.$store.commit('setFilters', this.filtersActive);
			WebStorage.removeItem('applyPage');
			this.initPageEquivalence();
		},

		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		limitedUsersShare(listUsers){
			return listUsers.slice(0,this.numCells);
		},

		showModal() {
			this.$store.dispatch('ui/openModal', CREATE_EQUIVALENCE_OPTIONS_MODAL);
		},

		updateNumCells() {
			const windowWidth = window.innerWidth;
			const sideMenuSize = !this.isCollapsed ? 213 : 0;

			if (windowWidth >= 1900 + sideMenuSize ) {
				this.numCells = 19;
			} else if (windowWidth >= 1600 + sideMenuSize) {
				this.numCells = 15;
			}	else if (windowWidth >= 1360 + sideMenuSize) {
				this.numCells = 8;
			} else if (windowWidth >= 1060 + sideMenuSize) {
				this.numCells = 5;
			} else {
				this.numCells = 3;
			}
		},
		goToEquivalence(id) {
			this.$router.push({
				name: 'equivalencesDetailing',
				params: { id },
			}).catch(()=>{});
		},
		editEquivalence(equivalenceId) {
			this.$router.push({
				name: 'editEquivalence',
				params: { id: equivalenceId },
			}).catch(()=>{});
		},
		async loadTable() {
			this.isLoadingTable = true;
			await this.restApi().getEquivalences(this.workspace.id)
				.then((response) => {
					this.equivalencesList = response;
				});
			this.isLoadingTable = false;
		},
		async deleteEquivalence() {
			this.isLoadingAction = true;

			try {
				await this.restApi().deleteEquivalence(this.workspace.id, this.activeEquivalence.id);

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('equivalences.delete.successMessage', { interpolate: { deletedEquivalence: truncate(this.activeEquivalence.nome) }}),
					status: 'success',
				});

				this.showAlert = false;
				this.$root.$emit('reloadList');
			} catch (error) {
				this.$store.commit('activeAlert',  {
					message: this.$locale.baseText('equivalences.delete.failMessage'),
					status: 'error',
				});
			} finally {
				this.isLoadingAction = false;
			}
		},
		date(data) {
			const newDate = new Date(data);
			const novaData = `${newDate.toLocaleDateString()} às ${newDate.toLocaleTimeString()}`;
			return novaData;
		},
		async pagination() {
			if (this.equivalencesList?.length % this.pageSize === 0) {
				this.page++;
				const payload = { ...this.$route.query, page: this.page };
				await this.restApi().getEquivalences(this.workspace.id, payload).then((response) => {
					for (const index in response) {
						this.equivalencesList.push(response[index]);
					}
				});
			} else {
				this.scrollInfinite = false;
			}
		},
		parseJwt(token) {
			return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
		},
		async loadTableEquivalences(options: { sizeInfinite: Boolean }) {
			if (options?.sizeInfinite) {
				this.isLoadingTable = true;
				this.pageSize = 99999;
			}
			const payload = { ...this.$route.query, page: 1, size: this.pageSize };
			try {
				await Promise.all([
					this.restApi().getEquivalences(this.workspace.id, payload),
				]).then((equivalencesList) => {
					if (options?.sizeInfinite) {
						this.pageSize = 10;
						this.scrollInfinite = false;
						equivalencesList[0].forEach((equivalence) => {
							if (equivalence.permissao == 'Editar') {
								equivalence.canShare = this.canShareEquivalence(equivalence);
								equivalence.selectedEquivalence = true;
							}
						});

						const equivalencesSelected = equivalencesList[0].filter(equivalence => equivalence.selectedEquivalence);
						this.arrayEquivalencesSelected = JSON.parse(JSON.stringify(equivalencesSelected));
						this.$root.$emit('selectAll', JSON.parse(JSON.stringify(equivalencesSelected)), {active: true, object: 'Equivalencia'});
						this.isLoadingTable = false;

					}
					this.equivalencesList = equivalencesList[0];
					this.equivalenceListTemp = this.equivalencesList;
				}).catch((err) => {
						console.log(err);
				});

			} catch (e) {
				console.log(e);
			}
		},

	},
	watch: {
		isCollapsed(newValue) {
			this.updateNumCells();
		},
	},
	beforeDestroy() {
		this.$root.$off("reloadList");
		window.removeEventListener('resize', this.updateNumCells);
	},
});
