
import moment from 'moment';
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import { mapGetters, mapActions } from 'vuex';
import { MAX_WORKFLOW_NAME_LENGTH } from '@/constants';

import WorkflowNameShort from '@/components/WorkflowNameShort.vue';
import TagsContainer from '@/components/TagsContainer.vue';
import PushConnectionTracker from '@/components/PushConnectionTracker.vue';
import WorkflowActivator from '@/components/WorkflowActivator.vue';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import SaveButton from '@/components/SaveButton.vue';
import TagsDropdown from '@/components/TagsDropdown.vue';
import InlineTextEdit from '@/components/InlineTextEdit.vue';
import BreakpointsObserver from '@/components/BreakpointsObserver.vue';
import Alert from '@/components/Alert.vue';
import Modal from '@/components/ModalAliare.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import { genericHelpers } from '@/components/mixins/genericHelpers';

import WebStorage from '@/common/WebStorage';
import { workflowPermission } from '@/components/mixins/permissions/workflow';

import {
	CREDENTIAL_SELECT_MODAL_KEY,
	TIMEZONE_SELECT_MODAL_KEY,
	EXECUTIONS_LIST_MODAL_KEY,
	TIMEOUT_EXECUTION_MODAL_KEY,
	HISTORY_VERSION_MODAL_KEY,
	ATTACHED_WORKFLOW_MODAL_KEY,
	DUPLICATE_WORKFLOW_MODAL_KEY,
} from '@/constants';
import { setTimeout } from 'timers';

const hasChanged = (prev: string[], curr: string[]) => {
	if (prev.length !== curr.length) {
		return true;
	}

	const set = new Set(prev);
	return curr.reduce((accu: any, val: any) => accu || !set.has(val), false);
};

export default mixins(
	workflowHelpers,
	genericHelpers,
	workflowPermission,
).extend({
	name: 'WorkflowDetails',
	components: {
		TagsContainer,
		PushConnectionTracker,
		WorkflowNameShort,
		WorkflowActivator,
		SaveButton,
		TagsDropdown,
		InlineTextEdit,
		BreakpointsObserver,
		Alert,
		Modal,
		ModalButtonSuccess,
		ModalButtonCancel,
		AlertNotificationModal,
	},
	data() {
		return {
			nameData: {
				isLoading: false,
				status: 'success',
			},
			isTagsEditEnabled: false,
			isNameEditEnabled: false,
			appliedTagIds: [],
			tagsEditBus: new Vue(),
			MAX_WORKFLOW_NAME_LENGTH,
			tagsSaving: false,
			saveAutomaticConfig: false,
			message: '',
			successAlert: false,
			errorAlert: false,
			saved: false,
			autoSaveInterval: '',
			active: false,
			workflowNameLength: '',
			flowName: '',
			workflowFalhaId: '',
			selectedTimezone: '',
			showModalTimezone: false,
			isLoadingAction: false,

			valueModal: '',
			idWorkflow: '',
			show_Modal: false,

			nameWithError: false,
			shareFlow: false,

			dataFlow: [],
			showAlert: false,
		};
	},
	computed: {
		...mapGetters({
			saveAutomatic: 'workflowSaveAutomatic',
			isWorkflowActive: 'isActive',
			workflowName: 'workflowName',
			isDirty: 'getStateIsDirty',
			currentWorkflowTagIds: 'workflowTags',
			hasChangeOnNode: 'hasChangeOnNode',
			projectData: 'projectData',
			typeExecutionWorkflow: 'isActive',
		}),

		isNewWorkflow(): boolean {
			return !this.$route.params.name;
		},
		workflowCanRedo(): boolean {
			return this.$store.getters.workflowCanRedo;
		},
		workflowCanUndo(): boolean {
			return this.$store.getters.workflowCanUndo;
		},
		isWorkflowSaving(): boolean {
			return this.$store.getters.isActionActive('workflowSaving');
		},
		currentWorkflowId(): string {
			return this.$route.params.name;
		},
		alertNotificationDescription() {
			return this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.message', {
				interpolate: { workflowName: this.workflowName },
			});
		},
		automatic() {
			return this.typeExecutionWorkflow;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async created() {
		this.idWorkflow = this.$route.params.name || '';
	},
	mounted() {
		// Auto Save
		this.autoSaveInterval = window.setInterval(() => {
			this.autoSave();
		}, 300000);

		this.$root.$on('sendDataStatus', this.getStatus);
	},
	methods: {
		...mapActions('auth', ['revokeTokens']),
		...mapActions('project', ['getProjectDetails', 'getProject']),
		...mapActions('workflows', [ 'patchWorkflowsWorkflowActive', 'patchWorkflowsWorkflowAutomatic']),

		valueChanged(value) {
			this.saveAutomaticConfig = value;
			this.$store.commit('setWorkflowSaveAutomatic', this.saveAutomaticConfig);
		},
		updateNameValueLength(value) {
			this.workflowNameLength = value.length;
		},
		undoActionWorkflow() {
			this.$root.$emit('undoWorkflow');
		},
		redoActionWorkflow() {
			this.$root.$emit('redoWorkflow');
		},
		goToVariable() {
			let route = this.$router.resolve({ name: 'variable' });
			window.open(route.href);
		},
		accessCredentials() {
			this.$store.dispatch('ui/openModal', CREDENTIAL_SELECT_MODAL_KEY);
		},
		workflowModifiedDate() {
			let testDateUtc = moment.utc(this.$store.getters.getWorkflowModifiedDate);
			let localDate = moment(testDateUtc).local().format('HH:mm');
			return localDate;
		},
		async autoSave() {

			if (this.$route.name === 'NodeViewNew' || this.$route.params.restore || !this.saveAutomatic) return;

			const saved = await this.saveCurrentWorkflow();
			if (saved) {
				this.$store.dispatch('settings/fetchPromptsData');
				this.saved = true;
				this.idWorkflow = this.$route.params.name;
			}
		},
		async onSaveButtonClick() {
			const saved = await this.saveCurrentWorkflow({ redirect: true });
			if (saved) {
				this.$store.dispatch('settings/fetchPromptsData');
				this.saved = true;
				this.idWorkflow = this.$route.params.name;
			}
		},
		onTagsEditEnable() {
			this.$data.appliedTagIds = this.currentWorkflowTagIds;
			this.$data.isTagsEditEnabled = true;

			setTimeout(() => {
				// allow name update to occur before disabling name edit
				this.$data.isNameEditEnabled = false;
				this.$data.tagsEditBus.$emit('focus');
			}, 0);
		},
		async onTagsUpdate(tags: string[]) {
			this.$data.appliedTagIds = tags;
		},

		async onTagsBlur() {
			const current = this.currentWorkflowTagIds;
			const tags = this.$data.appliedTagIds;
			if (!hasChanged(current, tags)) {
				this.$data.isTagsEditEnabled = false;

				return;
			}
			if (this.$data.tagsSaving) {
				return;
			}
			this.$data.tagsSaving = true;

			const saved = await this.saveCurrentWorkflow({ tags, redirect: true });
			this.$telemetry.track('User edited workflow tags', {
				workflow_id: this.currentWorkflowId as string,
				new_tag_count: tags.length,
			});

			this.$data.tagsSaving = false;
			if (saved) {
				this.$data.isTagsEditEnabled = false;
				this.idWorkflow = this.$route.params.name;
			}
		},
		onTagsEditEsc() {
			this.$data.isTagsEditEnabled = false;
		},
		onNameToggle() {
			this.$data.isNameEditEnabled = !this.$data.isNameEditEnabled;
			if (this.$data.isNameEditEnabled) {
				if (this.$data.isTagsEditEnabled) {
					// @ts-ignore
					this.onTagsBlur();
				}

				this.$data.isTagsEditEnabled = false;
			}
		},
		async onNameSubmit(name: string, cb: (saved: boolean) => void) {
			this.nameData.isLoading = true;
			const newName = name.trim();
			if (!newName) {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('workflowDetails.showMessage.message'),
					status: 'error',
				});

				cb(false);
				this.nameData.status = 'no-name';
				this.nameData.isLoading = false;
				return;
			}

			if (newName === this.workflowName) {
				this.$data.isNameEditEnabled = false;
				this.nameData.status = 'same-name';
				this.nameData.isLoading = false;
				cb(true);
				return;
			}

			const nameAvailable = await this.$store.dispatch('workflows/verifyNameAvailable', {projetoId: this.$route.params.projectid || this.$store.getters.getWorkflowProjectId, name: newName});

			if (!nameAvailable) {
				this.nameWithError = true;
				this.$store.commit('activeAlert', {
					message: 'O Projeto já possui um fluxo com este nome, tente novamente.',
					status: 'error',
				});
				this.nameData.status = 'name-unavailable';
				this.nameData.isLoading = false;
				this.$refs.inputName.$children[0].$children[0].$el.focus();
				cb(false);
				return;
			}

			this.nameWithError = false;

			const saved = await this.saveCurrentWorkflow({ name, redirect: true });

			if (saved) {
				this.nameData.status = 'success';
				this.$data.isNameEditEnabled = false;
				this.saved = true;
				this.idWorkflow = this.$route.params.name;
			}

			this.nameData.isLoading = false;
			cb(saved);
		},

		changeActive(activator) {
			const status = { workspaceId: this.workspace.id, id: this.$route.params.name, ativo: activator };
			const FlowName = this.workflowName;

			this.patchWorkflowsWorkflowActive(status)
				.then((response) => {
					const messageKey = response.data.ativo
						? 'projectDetailing.statusFlowActive'
						: 'projectDetailing.statusFlowInactive';

					this.$store.commit('activeAlert', {
						message: this.$locale.baseText(messageKey, { interpolate: { FlowName } }),
						status: response.data.ativo ? undefined : 'alert',
					});

					this.$store.commit('setWorkflowAtivo', response.data.ativo);
					this.$root.$emit('reloadNodeView');
				})
				.catch((error) => {
					this.active = !activator;
					const ErrorStatus = error.response?.data?.notifications;
					this.$store.commit('activeAlert', {
						message:  this.$locale.baseText('projectDetailing.errorActiveOrInactive', { interpolate: {FlowName, ErrorStatus} }),
						status: 'error',
					});
				});
		},



		async handleSelect(key: string) {
			this.isLoadingAction = true;
			if (key === 'workflow-delete') {
				const deleteConfirmed = true;

				if (deleteConfirmed === false) {
					//clearInterval(myInterval);
					return;
				}

				try {
					//clearInterval(myInterval);
					await this.restApi().deleteWorkflow(this.workspace.id, this.currentWorkflowId);

					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('mainSidebar.showMessage.handleSelect1.message', {
							interpolate: { workflowName: this.workflowName },
						}),
					});

					this.showAlert = false;

					setTimeout(() => {
						this.$router.push({
							name: "projectDetailing",
							params: { id: this.$store.getters.getWorkflowProjectId },
						});
						}, 2000);
				} catch (error) {
					//clearInterval(myInterval);

					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('mainSidebar.showError.workflowDeleteMessage'),
						status: 'error',
					});
					return;
				}
				//clearInterval(myInterval);
				this.$store.commit('setStateDirty', false);
				// Reset tab title since workflow is deleted.
				this.$titleReset();
				this.$showMessage({
					title: this.$locale.baseText('mainSidebar.showMessage.handleSelect1.title'),
					message: this.$locale.baseText('mainSidebar.showMessage.handleSelect1.message', {
						interpolate: { workflowName: this.workflowName },
					}),
					type: 'success',
				});

				this.$router.push({ name: 'NodeViewNew' });
			} else if (key === 'workflow-save') {
				if (this.$data.isNameEditEnabled) return;

				const saved = await this.saveCurrentWorkflow({ redirect: true });

				if (saved) {
					this.$store.dispatch('settings/fetchPromptsData');
					this.saved = true;
					this.idWorkflow = this.$route.params.name;
				}
			} else if (key === 'workflow-duplicate') {
				this.$store.dispatch('ui/openModal', DUPLICATE_WORKFLOW_MODAL_KEY);
			} else if (key === 'timezone') {
				this.$store.dispatch('ui/openModal', TIMEZONE_SELECT_MODAL_KEY);
			}  else if (key === 'executions') {
				this.$store.commit('setOpenRouteName', 'viaWorkflow');
				this.$store.dispatch('ui/openModal', EXECUTIONS_LIST_MODAL_KEY);
			} else if (key === 'timeoutExecution') {
				this.$store.dispatch('ui/openModal', TIMEOUT_EXECUTION_MODAL_KEY);
			} else if (key === 'historyVersion') {
				this.$store.dispatch('ui/openModal', HISTORY_VERSION_MODAL_KEY);
			} else if (key === 'attachedWorkflows') {
				this.$store.dispatch('ui/openModal', ATTACHED_WORKFLOW_MODAL_KEY);
			}
			this.isLoadingAction = false;
		},

		showManageModal(workflowId) {
			this.$store.dispatch('ui/openManageVariableModal', {	data: { workflowId }});
		},
		openSettingsExecutionModal() {
			const { settings } = WebStorage.getItem('setWorkflowData').workflowData;
			const errorWorkflow = settings.errorWorkflow;

			if (errorWorkflow !== undefined && errorWorkflow !== '') {
				this.$store.dispatch('ui/openSettingsExecutionModal', {
					data: { workflowFalhaId: errorWorkflow, workflowId: this.$route.params.name },	mode: 'changeFailure',
				});
				return;
			}
			this.$store.dispatch('ui/openSettingsExecutionModal', {	data: { workflowId: this.$route.params.name }, mode: 'defineFailure'});
		},

		async getStatus(data) {
			this.active = data.ativo;
			this.$store.commit('setActive', data.automatico);
		},
	},
	beforeDestroy() {
		clearInterval(this.autoSaveInterval);
		this.$root.$off('sendDataStatus', this.getStatus);
	},
	watch: {
		currentWorkflowId() {
			this.$data.isTagsEditEnabled = false;
			this.$data.isNameEditEnabled = false;
		},
		workflowName(newValue) {
			if (newValue.length) this.workflowNameLength = newValue.length;
    },
	},
});
