
import AliareSelect from '@/components/AliareSelect.vue';
import AliareInput from '@/components/AliareInput.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import { mapActions } from 'vuex';
import { showMessage } from '@/components/mixins/showMessage';
import TokenService from '@/services/token.service';
import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import Alert from '@/components/Alert.vue';
import WebStorage from '@/common/WebStorage';
import { variablePermission } from '@/components/mixins/permissions/variable';

export default mixins(
	restApi,
	showMessage,
	variablePermission,
).extend({
	name: 'NewVariable',
	components: {
		BackgroundView,
		Alert,
		AliareInput,
		AliareSelect,
		NotificationPage,
		ModalButtonCancel,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	data() {
		return {
			isLoadingTable: false,
			queryInput: '',
			message: '',
			workflowSelected: '',
			variableValue: '',
			variable: '',
			validation: false,
			flow: '',
			project: '',
			showSelect: true,
			workflowList: '',
			verifyVariableName: [],

			showAlert: false,
			showNotification: false,
		};
	},
	computed: {
		alertNotificationTitle() {
			return 'Cancelamento do Cadastro';
		},
		alertNotificationDescription() {
			return 'Todas as parametrizações que estão sendo feitas serão <b>excluídas</b> e você <b>não poderá recuperá-las</b>. O que você deseja fazer?';
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async created() {
		this.isLoadingTable = true;
		await this.verifyPermissionUserAndInitiate();
		this.isLoadingTable = false;
	},
	methods: {
		...mapActions('workflows', ['getWorkflowsIdVariable']),

		async verifyPermissionUserAndInitiate() {
			await this.validationAccessVariables().then(async () => {
				if (this.createVariablePermission) {
					await this.getInitWorkflow();
				} else {
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('permission.noPermissionFuncionality'),
						status: 'error',
					});
					this.$router.push({
						name: 'variable',
					});
				}
			});
		},
		parseJwt(token) {
			return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
		},
		disableInput() {
			this.showSelect = false;
		},
		openModalAlert() {
			this.showAlert = true;
		},
		async redirectVariable() {
			this.$router.push({ name: 'variable' });
		},
		goToCreateNewVariable() {
			window.location.reload();
		},
		activeSearch() {
			this.showSelect = !this.showSelect;
			this.$nextTick(() => {
				this.$refs.aliareInput.$refs.input.focus();
			})
		},
		async searchInfoByName() {
			if (this.queryInput.length > 2) {
				try {
					this.workflowList = await this.restApi().getWorkflows(this.workspace.id, {
						nome: this.queryInput,
						size: 5,
					});
				} catch (e) {
					console.log(e);
				}
			} else {
				// Limpar o data quando menor que 2 caracteres no input
				this.workflowList = [];
				this.getInitWorkflow();
			}
		},
		async getInitWorkflow() {
			if(this.queryInput.length === 0) {
				this.showSelect = false;
				try {
						this.workflowList = await this.restApi().getWorkflows(this.workspace.id, {
							size: 5,
						});

						this.workflowList.sort(function(x,y) {
							let a = x.nome.toUpperCase(),
									b = y.nome.toUpperCase();
							return a == b ? 0 : a> b ? 1 : -1;
						});
				} catch (e) {
						console.log(e);
				}
			}
		},
		variablePost() {
			this.restApi().createNewVariable(this.workspace.id, {
				workflowId: this.workflowSelected.id,
				nome: this.variable,
				valor: this.variableValue,
			})
			.then((response) => {
				this.showNotification = true;
			})
			.catch((error) => {
				this.$store.commit('activeAlert', {
					message: `Erro ao criar variável`,
					status: 'error',
				});
			});
		},
		verifyVariable() {
			if (this.verifyVariableName.length === 0) {
				this.validation = true;
			} else {
				for (const index in this.verifyVariableName) {
					if (this.variable === this.verifyVariableName[index].nome) {
						this.validation = false;
						break;
					} else {
						this.validation = true;
					}
				}
			}
		},
		getVariable(data) {
			this.verifyVariableName = [];
			this.getWorkflowsIdVariable({workspaceId: this.workspace.id, id: data.id})
				.then((response) => {
					this.verifyVariableName = response.data;
				})
				.catch((error) => {
					console.log('error', error);
				});
		},
	},
});
