import { hasPermissionByDescription, verifyPermissionDescription, verifyAdminUser } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const userPermission = Vue.extend({
	data() {
		return {
			PERMISSION_IDS,
			teams: '',
			dataUsers: '',
			isAdmin: '',
		};
	},

	async created() {
		await this.validationAccessUsers();
	},

	computed: {
		manageUserPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageUsersId, this.teams?.childrens);
		},

		registerUserPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.registerUserId, this.dataUsers?.childrens);
		},
		editUserPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.editUserId, this.dataUsers?.childrens);
		},
		activeInactiveUserPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.activeInactiveUserId, this.dataUsers?.childrens);
		},
	},

	methods: {
		async validationAccessUsers() {
			this.isAdmin = await verifyAdminUser();
			const permission = JSON.parse(WebStorage.getItem('userPermission'));
			this.teams = verifyPermissionDescription(this.PERMISSION_IDS?.manageTeamsId, permission?.access);
			this.dataUsers = verifyPermissionDescription(this.PERMISSION_IDS?.manageUsersId, this.teams?.childrens);

			if (!this.isAdmin || (!this.teams && !this.dataUsers)) {
				this.$router.push({
					name: 'workspace',
				});

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('permission.noPermissionFuncionality'),
					status: 'error',
				});
			} else if (!this.dataUsers && (this.$route.name == 'registerUser' || this.$route.name == 'userdetailing')) {
				this.$router.push({
					name: 'users',
				});
			}
		},

		canViewDetailUser() {
			return this.manageUserPermission && this.isAdmin;
		},
		canViewSendEmail(emailConfirmed) {
			if (emailConfirmed !== undefined) {
				return this.manageUserPermission && !emailConfirmed && this.isAdmin ? true : false;
			}
			return this.manageUserPermission && this.isAdmin;
		},

		canViewRegisterUser() {
			return this.registerUserPermission;
		},
		canViewEditUser(activePermission) {
			if (activePermission !== undefined) {
				return this.editUserPermission && activePermission && this.isAdmin ? true : false;
			}
			return this.editUserPermission && this.isAdmin;
		},
		canViewActiveInactiveUser() {
			return this.activeInactiveUserPermission && this.isAdmin;
		},
	},
});
