
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { genericHelpers } from '@/components/mixins/genericHelpers';

import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import moment from 'moment';

import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import Modal from '@/components/ModalAliare.vue';
import AliareInput from '@/components/AliareInput.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import FilterBar from '@/components/FilterBar.vue';


import { removeItemFromArrayByValue, filter, truncate } from '@/components/helpers';
import { previousRoute } from '../router/index.ts';
import WebStorage from '@/common/WebStorage';

export default mixins(
	nodeHelpers,
	showMessage,
	genericHelpers,
).extend({
	components: {
		RoundPhotoFrame,
		BackgroundView,
		ModalButtonCancel,
		ModalButtonSuccess,
		Modal,
		AliareInput,
		AlertNotificationModal,
		FilterIcon,
		FilterBar,
	},
	name: 'IntegrationDetailing',
	data() {
		return {
			isSearching: false,
			isLoadingAction: false,

			searchingList: '',
			currentIntegration: '',
			showAlertChanges: true,
			activeTab: 'registers',
			isLoading: false,
			showAlert: {
				active: false,
				mode: '',
				data: '',
			},

		};
	},
	computed: {
		discardModalText() {
			if(this.showAlert.mode == 'publish'){
				return this.$locale.baseText('Publicar Integração');
			} else if(this.showAlert.mode == 'suspend'){
				return this.$locale.baseText('Manter Integração Publicada');
			}
			return this.$locale.baseText('Manter Integração');
		},
		keepModalText() {
			if(this.showAlert.mode == 'publish'){
				return this.$locale.baseText('Cancelar');
			} else if(this.showAlert.mode == 'suspend'){
				return this.$locale.baseText('Suspender Integração');
			}
			return this.$locale.baseText('Deletar Integração');
		},
		alertNotificationTitle() {
			if(this.showAlert.mode == 'publish'){
				return this.$locale.baseText('Publicar Integração');
			} else if(this.showAlert.mode == 'suspend'){
				return this.$locale.baseText('Suspender Publicação');
			}
			return this.$locale.baseText('Exclusão de Intregração');
		},

		alertNotificationDescription() {
			if(this.showAlert.mode == 'publish'){
				return `Ao prosseguir a  integração será <b>publicada</b> e <b>será vista e acessada pelo cliente</b>. O que você deseja fazer?`;
			} else if(this.showAlert.mode == 'suspend'){
				return `Ao prosseguir a  integração será <b>suspensa</b> e <b>não poderá ser acessada pelo cliente</b>. Ela estará disponível na listagem para caso queria publicá-la novamente. O que você deseja fazer?`;
			}
			return `Ao prosseguir a integração será <b>excluída</b> e você <b>não poderá recuperá-la</b>. O que você deseja fazer?`;
		},
		idIntegration() {
			return this.$route?.params?.id;
		},
		workspace() {
			return this.$store.getters.workspace;
		},

	},
	async mounted() {
		this.$root.$on('reloadList', () => {
			this.loadCurrentIntegration();
		});
	},
	created() {
		this.verifyPermissionUserAndInitiate();
	},

	methods: {
		closeAlert() {
			this.showAlert.active = false;
			this.showAlert.data = {};
			this.showAlert.mode = '';
		},

		goToStoreDetailling(id) {
			this.$router.push({
				name: 'knowMoreIntegration',
				params: { id },
			});
		},

		async publishIntegration(data) {
			try {
				this.closeAlert();
				this.$store.commit('activeAlert', {
					message: `Iniciamos a publicação da integração, isso poderá levar alguns minutos. Informaremos quando concluímos.`,
					status: 'info',
				});

				await this.restApi().publishIntegration(data.id);

				this.loadCurrentIntegration();
				this.$store.commit('activeAlert', {
					message: `A integração "${data.titulo}" foi publicada com sucesso.`,
				});
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: `Falha ao publicar integração, tente novamente. ${error.notifications}`,
					status: 'error',
				});
			}
		},

		async suspendIntegration(data) {
			this.isLoadingAction = true;

			try {
				await this.restApi().suspendIntegrationPublished(this.idIntegration);

				this.closeAlert();
				this.$store.commit('activeAlert', {
					message: `A integração "${data.titulo}" foi suspensa com sucesso.`,
				});
				this.loadCurrentIntegration();
			} catch (error) {
				this.$store.commit('activeAlert', {
					message:  error.notifications,
					status: 'error',
				});
			}

			this.isLoadingAction = false;
		},

		openAlert(mode, integration) {
			this.showAlert.mode = mode;
			this.showAlert.data = integration;
			this.showAlert.active = true;
		},

		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		async verifyPermissionUserAndInitiate() {
			await this.loadCurrentIntegration();
		},

		editIntegration() {
			this.$router.push({
				name: 'editIntegration',
				params: { id: this.idIntegration },
			});
		},
		async deleteIntegration(data) {
			this.isLoadingAction = true;

			try {
				if(this.currentIntegration.status == 'Publicado') {
					this.$store.commit('activeAlert', {
						message: `A integração "${this.currentIntegration.titulo}" está publicada e não pode ser excluída.`,
						status: 'error',
					});

					this.closeAlert();
					return;
				}
				await this.restApi().deleteIntegrations(data.id);

				this.closeAlert();
				this.$store.commit('activeAlert', {
					message: `A integração "${data.titulo}" foi excluída com sucesso.`,
				});

				this.$router.push({
					name: 'integrations',
				});
			} catch (error) {
				this.$store.commit('activeAlert', {
					message:  error.notifications,
					status: 'error',
				});
			}
			this.isLoadingAction = false;

		},

		date(data) {
			const newDate = new Date(data);
			const novaData = `${newDate.toLocaleDateString()} às ${newDate.toLocaleTimeString()}`;
			return novaData;
		},

		async loadCurrentIntegration() {
			this.isLoading = true;

			try {
				this.currentIntegration = await this.restApi().getIntegrationById(this.idIntegration);
			} catch (error) {
				console.log(error);
				this.$store.commit('activeAlert', {
					message: 'Erro ao carregar a integração',
					status: 'error',
				});

				this.$router.push({
					name: 'integrations',
				});
				return;
			} finally {
				this.isLoading = false;
			}
		},
	},
	beforeDestroy() {
	},
});
