
import { showMessage } from '@/components/mixins/showMessage';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';

import Modal from '@/components/ModalAliare.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import MenuOptions from '@/components/MenuOptions.vue';
import CredentialInfo from '@/components/CredentialEdit/CredentialInfo.vue';
import ActiveDeactivateButton from '@/components/ActiveDeactivateButton.vue';
import CredentialIcon from '../components/CredentialIcon.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import { previousRoute } from '../router/index.ts';

import { mapActions } from 'vuex';

import {
	CredentialInformation,
	ICredentialDataDecryptedObject,
	ICredentialNodeAccess,
	ICredentialsDecrypted,
	ICredentialType,
	INodeParameters,
	INodeProperties,
	INodeTypeDescription,
	NodeCredentialTestResult,
	NodeHelpers,
} from 'n8n-workflow';

import mixins from 'vue-typed-mixins';

import WebStorage from '@/common/WebStorage';
import { credentialPermission } from '@/components/mixins/permissions/credentials';


import {
	ICredentialsDecryptedResponse,
} from '@/Interface';

interface NodeAccessMap {
	[nodeType: string]: ICredentialNodeAccess | null;
}

export default mixins(
	nodeHelpers,
	showMessage,
	credentialPermission,
	genericHelpers,
).extend({
	name: 'CredentialDetailing',
	components: {
		Modal,
		BackgroundView,
		MenuOptions,
		ActiveDeactivateButton,
		CredentialIcon,
		ModalButtonSuccess,
		ModalButtonCancel,
		CredentialInfo,
		AlertNotificationModal,
	},
	data() {
		return {
			credentialData: {},
			credentialName: '',
			credentialId: '',
			activeTab: 'connection',
			credentialTypeName: '',
			credentialType: '',
			currentCredential: '',
			isLoading: true,
			nodeAccess: {} as NodeAccessMap,
			nodesWithAccess: '',
			showAlert: false,
			isLoadingAction: false,
		};
	},
	computed: {
		alertNotificationTitle() {
			return 'Excluir Credencial';
		},
		alertNotificationDescription() {
			return `Ao excluir a Credencial <b>${ this.currentCredential.nome }</b> você não poderá recuperá-las. O que você deseja fazer?`;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	created() {
		this.verifyPermissionUserAndInitiate();
	},
	async mounted() {
		this.$root.$on('reloadList', () => {
			this.loadCurrentCredential();
		});
	},

	methods: {
		...mapActions('credentials', ['getCredential']),
		...mapActions('auth', ['revokeTokens']),

		async loadAndFetchCredentials() {
			const loadPromises = [
				this.loadNodeTypes(),
			];

			try {
			await Promise.all([
					await this.$store.dispatch('credentials/fetchCredentialTypes'),
					await this.$store.dispatch('credentials/fetchAllCredentials'),
					await (loadPromises),
				]);
			}	catch (e) {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('credentialsList.errorOnLoadingCredentials'),
					status: 'error',
				});
			} finally {
				await this.loadCurrentCredential();
			}
		},
		async verifyPermissionUserAndInitiate() {
			this.isLoading = true;
			await this.validationAccessCredentials().then(async () => {
				if (this.canViewDetailCredential()) {
					await this.loadAndFetchCredentials();
				} else {
					this.$store.commit('setData403', {show: true, prevPage: previousRoute});
				}
			});
		},
		onTabSelect(tab: string) {
			this.activeTab = tab;
		},
		async loadNodeTypes(): Promise<void> {
			const nodeTypes = await this.restApi().getNodeTypes();

			this.$store.commit('setNodeTypes', nodeTypes);
		},
		editCredential () {
			this.$store.dispatch('ui/openExisitngCredential', { id: this.currentCredential.id});
		},

		async deleteCredential () {
			this.isLoadingAction = true;

			try {
				await this.$store.dispatch('credentials/deleteCredential', {id: this.currentCredential.id, workspaceId: this.workspace.id});
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('credentialsList.showMessage.message', { interpolate: { savedCredentialName: this.currentCredential.nome }}),
					status: 'success',
				});

				this.showAlert = false;

				this.$router.push({
					name: 'credentials',
				});

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.notifications[0],
					status: 'error',
				});
			}

			this.isLoadingAction = false;
		},

		date(data) {
			const newDate = new Date(data);
			const novaData = `${newDate.toLocaleDateString()} às ${newDate.toLocaleTimeString()}`;
			return novaData;
		},
		verifySecretValue(value) {
			if (value == '[SecretValue]') {
				return `
					<i class="encryptedIcon"></i>
					<p>${this.$locale.baseText('credentialDetailing.content.encryptedText')}</p>
				`;
			} else {
				return value;
			}
		},
		async loadCurrentCredential() {
			this.isLoading = true;
			this.credentialId = this.$route.params.id;
			this.nodeAccess = {};

			try {
				const currentCredentials: ICredentialsDecryptedResponse =
					await this.$store.dispatch('credentials/getCredentialData', {
						id: this.credentialId,
						workspaceId: this.workspace.id,
					});

				this.currentCredential = currentCredentials;
				this.credentialTypeName = currentCredentials.tipo;
				this.credentialType = this.$store.getters['credentials/getCredentialTypeByName'](
					this.credentialTypeName,
				);


				if (currentCredentials.credencialData.nodesAccess !== undefined) {
					currentCredentials.credencialData.nodesAccess.forEach(
						(access: { nodeType: string }) => {
							// keep node access structure to keep dates when updating
							this.nodeAccess[access.nodeType] = access;
						},
					);
				}

				this.nodesWithAccess = this.$store.getters['credentials/getNodesWithAccess'](
					this.credentialTypeName,
				);

			} catch (error) {
				this.$router.push({
					name: 'credentials',
				});
				return;
			} finally {
				this.isLoading = false;
			}
		},
	},
	beforeDestroy() {
		this.$root.$off("reloadList");
	},
});
