import TokenService from "@/services/token.service";
import AuthService from "@services/auth.service";
import { defaultApi } from '@/api/internalApis';
import WebStorage from "@/common/WebStorage";


export const setupHeaderAliareTenant = (tenantId) => {
	defaultApi.interceptors.request.use(
		(config) => {
			config.headers["X-Tenant"] = WebStorage.getItem("tenantId");
			return config;
		},
		(error) => {
			return Promise.reject(error);
		},
	);
};

export const setupHeaderBearerAuthorization = (apiList, store) => {
	apiList.forEach(commonApi => {
		commonApi.interceptors.request.use(
			(config) => {
				const token = TokenService.getTokens();

				if (token) {
					config.headers["Authorization"] = 'Bearer ' + token?.access_token;  // for Spring Boot back-end
				}

				return config;
			},
			(error) => {
				return Promise.reject(error);
			},
		);

		commonApi.interceptors.response.use(
			(response) => response,
			async (error) => {
				const originalConfig = error.config;

				if (originalConfig.url !== AuthService.getSignInUrl() && error.response) {
					// Access Token was expired

					if (error.response.status === 401 && !originalConfig._retry) {
						originalConfig._retry = true;

						try {
							const response = await AuthService.silentRefresh();
							const { status } = response;

							if (status == '204') {
								store.dispatch('auth/updateTokens', TokenService.getTokens());
							} 

							return commonApi(originalConfig);
						} catch (_error) {
							await AuthService.logout();
							return Promise.reject(_error);
						}
					}
				}

				return Promise.reject(error);
			},
		);
	});
};

export default setupHeaderBearerAuthorization;
