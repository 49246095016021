import { hasPermissionByDescription, verifyPermissionDescription } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const executionLogPermission = Vue.extend({
	data() {
		return {
			PERMISSION_IDS,
			dataLogs: '',
			dataLogsExecution: '',
			dataLogsExecutionDetail: '',
			detailAttachedExecution: '',
			acessAttachedExecution: '',
		};
	},

	async created() {
		await this.validationAccessExecutionLog();
	},

	computed: {
		acessExecutionPermission() {
			if (this.$store.getters.routeName === 'viaWorkflow' || this.$store.getters.routeName === 'viaDetailProject') {
				return hasPermissionByDescription(this.PERMISSION_IDS?.openFlowId, this.dataLogsExecution?.childrens)
					|| hasPermissionByDescription(this.PERMISSION_IDS?.editFlowId, this.dataLogsExecution?.childrens);
			}
			return hasPermissionByDescription(this.PERMISSION_IDS?.acessExecutionsId, this.dataLogs?.childrens);
		},
		reprocessExecutionPermission() {
			if (this.$store.getters.routeName === 'viaWorkflow' || this.$store.getters.routeName === 'viaDetailProject') {
				return hasPermissionByDescription(this.PERMISSION_IDS?.openFlowId, this.dataLogsExecution?.childrens)
					|| hasPermissionByDescription(this.PERMISSION_IDS?.editFlowId, this.dataLogsExecution?.childrens);
			}
			return hasPermissionByDescription(this.PERMISSION_IDS?.reprocessExecutionId, this.dataLogsExecution?.childrens);
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},

	methods: {
		async validationAccessExecutionLog() {
			const permission = JSON.parse(WebStorage.getItem('userPermission'));
			const routeName = this.$store.getters.routeName;

			if (routeName === 'viaWorkflow' || routeName === 'viaDetailProject') {
				this.dataLogs = verifyPermissionDescription(this.PERMISSION_IDS?.manageIntegrationId, permission?.access);
				this.dataLogsExecution = verifyPermissionDescription(this.PERMISSION_IDS?.manageProjectId, this.dataLogs?.childrens);

			} else {
				this.dataLogs = verifyPermissionDescription(this.PERMISSION_IDS?.manageLogs, permission?.access);
				this.dataLogsExecution = verifyPermissionDescription(this.PERMISSION_IDS?.acessExecutionsId, this.dataLogs?.childrens);
			}

			if (!this.dataLogs && !this.dataLogsExecution) {
				this.$router.push({ name: 'project' });

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('permission.noPermissionFuncionality'),
					status: 'error',
				});
			} else if (!this.dataLogsExecution) {
				this.$router.push({
					name: 'executionLog',
				});
			}
		},

		canAcessExecution() {
			return this.acessExecutionPermission && this.workspace.ativo;
		},

		canViewAccessAttachedExecution(quantidadeDeExecucoesAnexadas) {
			if (quantidadeDeExecucoesAnexadas !== undefined) {
				return this.acessExecutionPermission && quantidadeDeExecucoesAnexadas > 0 && this.workspace.ativo;
			}
			return this.acessExecutionPermission && this.workspace.ativo;
		},

		canViewReprocessExecution(resultExecution) {
			if (resultExecution !== undefined) {
				return this.reprocessExecutionPermission && resultExecution === 'Falha' && this.workspace.ativo ? true : false;
			}
			return this.reprocessExecutionPermission && this.workspace.ativo;
		},
	},
});
