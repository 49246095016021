<template>
	<div>
	<background-view :isLoading="isLoadingPage">
		<AlertNotificationModal
			v-if="showAlert"
		>
			<template v-slot:icon>
				<i class="equivalenceCreateSuccessIcon"></i>
			</template>

			<template v-slot:title>
				{{ 'Exclusão do Perfil' }}
			</template>
			<template v-slot:firstText>
				<p v-html="alertNotificationDescription"></p>
			</template>

			<template v-slot:footer>
				<ModalButtonSuccess
					:titleButton="'Manter Perfil'"
					width="300"
					@click="showAlert = false"
					profileIcon
					active
				/>
				<ModalButtonCancel
					width="300"
					trashIcon
					:loadingAction="isLoadingAction"
					@click="deletProfile(currentProfile.productProfile)"
					:titleButton="'Excluir Perfil'"
				/>
			</template>
		</AlertNotificationModal>

		<interaction-menu
			:InteractionMenuTitle="'Perfis Cadastrados'"
			:interactionMenuButtonToCleanFilter="'credentials.interactionMenu__buttonToCleanFilter'"
			:interactionMenuButtonToRegisterVariableBoolean="true"
			:interactionMenuButtonToRegisterVariable="'Cadastrar Perfil'"
			:interactionMenuLink="interactionMenu__link"
			:interactionMenuFilter="filtering"
			:alwaysActive="canViewRegisterUserProfile()"
			:viewClearFilter="false"
		></interaction-menu>

		<FilterBar
			@search-applied="filterBySearch"
			@filters-applied="filterTable"
			@clearFilter="clearFilter"
			:filtering="filtering"
			:clearfiltering="clearfiltering"
			:activeFilters="filtersActive"
			:defaultInitialSearch="$route.query?.search"
			:placeholder="'Filtre por perfil'"
		>
		</FilterBar>

		<Table
			:maxheight="maxheight"
			:tableOverflow="true"
			class="profile_table"
			:scrollInfinite="scrollInfinite && !filtering"
			:filtering="filtering"
			@endScroll="pagination()"
			id="tabela"
			:textIfEmpty="$locale.baseText('profile.emptyList')"
			:isLoading="isLoadingTable"
		>
			<template v-slot:headerColumns>
				<tr class="table__tableTheadTr">
					<th class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('Perfis') }}
							</div>
						</div>
					</th>
					<th class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('Usuários Vínculados') }}
							</div>
						</div>
					</th>

					<th width="160px" class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('Status') }}
							</div>
						</div>
					</th>

					<th width="150px" class="table__tableTheadTrTh profile_tableHeaderAux">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('credentials.tableTitle__actions') }}
							</div>
						</div>
					</th>
				</tr>
			</template>
			<template v-slot:columns>
				<tr v-for="row in dataTable" :key="row.id">
					<td>
						<div @click="goToProfileDetailing(row.id)" class="profile_tableProfileName">
							{{ row.productProfile.description }}
						</div>
					</td>

					<td>
						<div class="profile_tablePictures">
							<div v-for="(user, index) in limitedUsers(row.usersProfiles)" v-if="!user.blocked" :key="index">
								<el-tooltip :content="user?.name" placement="bottom">
								<RoundPhotoFrame
									:key="user?.userId"
									size="32"
									:image="user?.picture"
									:text="user?.name"
									alt="foto-avatar"
								/>
								</el-tooltip>
							</div>
							<el-tooltip v-if="row?.usersProfiles?.length > numCells" placement="bottom">
								<div slot="content">
									<div class="more-users"><h3>Demais Usuarios</h3><p v-for='(user, index) in row.usersProfiles.slice(numCells)' :key="index">{{ user.name }}</p></div>
								</div>

								<div  class="more-photos"> +{{ row?.usersProfiles?.length - numCells }}</div>
							</el-tooltip>
						</div>
					</td>

					<td width="160px">
						<div
							class="users__Column_Frame"
							v-bind:class="{
								users_Active: row.statusActive,
								users_Inactive: !row.statusActive,
							}"
						>
							<div v-if="row.statusActive">{{ $locale.baseText('Ativo(a)') }}</div>
							<div v-else-if="!row.statusActive">{{ $locale.baseText('Inativo(a)') }}</div>
						</div>
					</td>

					<td width="150px" class="profile_tableActionsCenter">
						<div class="profile_tableActionsFrame">
							<el-tooltip	:content="canViewDetailUserProfile() ? 'Ver Detalhes' : $locale.baseText('permission.noPermissionFuncionality')"
								placement="bottom"
							>
								<div
									class="profile_tableActionsButtonLeft eyeButton"
									@click.stop="canViewDetailUserProfile() && goToProfileDetailing(row.id)"
									:class="{ 'eyeButton--disableEye': !canViewDetailUserProfile() }"
								/>
							</el-tooltip>

							<el-tooltip	:content="canViewDetailUserProfile() ? 'Editar Perfil' : $locale.baseText('permission.noPermissionFuncionality')"
								placement="bottom"
							>
								<div
									class="profile_tableActionsButtonLeft editButton"
									@click.stop="canViewEditUserProfile() && goToEditProfile(row)"
									:class="{ 'editButton--disableEdit': !canViewEditUserProfile() }"
								/>
							</el-tooltip>

							<el-tooltip :disabled="false"	:content=" 'Excluir Perfil'" placement="bottom"	>
								<div
									class="profile_tableActionsButtonLeft deleteButton"
									@click.stop="openAlertModal(row)"
									:class="{ 'deleteButton--disableDelete': false }"
								/>
							</el-tooltip>
						</div>
					</td>
				</tr>
			</template>
			<template v-slot:footerColumns>
				<div></div>
			</template>
		</Table>
	</background-view>
	</div>
</template>

<script>
import Table from '@/components/Table.vue';
import FilterTable from '@/components/FilterTable.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import FilterIcon from '@/components/FilterIcon.vue';
import FilterBar from '@/components/FilterBar.vue';
import { previousRoute } from '../router/index.ts';

import { mapActions, mapGetters } from 'vuex';
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { userProfilePermission } from '@/components/mixins/permissions/userProfile';
import {
	filter,
	addPictureFromUsers,
	removeDuplicateUsers,
} from '@/components/helpers';


export default mixins(
	userProfilePermission

).extend({
	components: {
		Table,
		FilterBar,
		FilterIcon,
		FilterTable,
		SelectFilter,
		BackgroundView,
		RoundPhotoFrame,
		InteractionMenu,
		ModalButtonCancel,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	name: 'Profile',
	data() {
		return {
			value: '',
			interactionMenu__link: 'createProfile',
			maxheight: '60vh',

			page: 1,
			pageSize: 10,
			isLoadingTable: false,
			isLoadingPage: false,
			scrollInfinite: true,
			filtering: false,
			dataTable: [],
			dataTableTemp: [],
			dataPagination: [],
			showAlert: false,
			currentProfile: '',
			organizationInfo: JSON.parse(WebStorage.getItem('accountInfo')),

			deleteProfile: true,
			isLoadingAction: false,
			usersActive: [],

			dataTableFilteredByName: [],
			applyedFilters: [],
			numCells: 7,
			windowWidth: 0,

			filtersActive: {},
			clearfiltering: false,
			previousRoute: previousRoute,
		};
	},
	async created() {
		this.isLoadingPage = true;
		await this.initProfile();
		this.isLoadingPage = false;
	},
	async mounted() {
		this.$root.$on('reloadList', () => {
			this.loadTable();
		});

		this.updateNumCells();
  	window.addEventListener('resize', this.updateNumCells);
	},
	computed: {
		...mapGetters('ui', {
			isCollapsed: 'sidebarMenuCollapsed',
		}),

		alertNotificationDescription() {
			return `Ao excluir o perfil todos os usuários vinculados a ele perderão sua permissões à organização <b>${ this.organizationInfo?.name }</b>. O que deseja fazer ?`;
		},
	},
	methods: {
		...mapActions('aliareAccount', ['getUserAccountId', 'getPermissionFromUser', 'getProfiles', 'verifyUserActive', 'getProfilesByTenantIdV2', 'deleteProductProfile']),
		...mapActions('auth', ['revokeTokens']),

		async initProfile() {
			const applyPage = WebStorage.getItem('applyPage');
			if (this.previousRoute.name == 'profileDetailing' && applyPage == 'profile' ) {
				this.$router.replace({query: { ...this.$store.getters?.filters } }).catch(() => {});
			};

			if(Object.keys(this.$route.query).length) {
				await this.filterTable();
			} else {
				await this.getAccount();
			}
		},

		async filterBySearch(search) {
			this.filtering = search.length < 1 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length < 1 ? '' : search);
		},
		async filterTable(filters) {
			try {
				this.isLoadingTable = true;
				const routeQuery = this.$route.query;
				const payload = {
					size: 10,
					search: filters?.filterSearch || routeQuery?.search,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.getProfiles(payload);

				await this.applyFilter(dataFilter, payload);
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoadingTable = false;
			}
		},
		async applyFilter(dataFiltered, query) {
			this.filtering = true;
			this.clearfiltering = true;

			await this.getInfoProfileFiltered(dataFiltered);

			this.$store.commit('setFilters', query);
			WebStorage.setItem('applyPage', 'profile');
		},
		async getInfoProfileFiltered(dataFiltered) {
			let profileData = await this.getProfilesByTenantIdV2();

			profileData.data.forEach((profile) => {
				let dataProfile = dataFiltered.find(dataProfile => dataProfile.id == profile.id)
				if (dataProfile) {
					dataProfile.statusActive = profile.active;
					dataProfile.solutionId = profile.solution.id;
				};
			});

			// Filtra pelo solutionId
			const filterData = dataFiltered.filter(item => item.solutionId == 'aca76e07-280f-4e1f-a4df-f8a94757e0fc')

			let data = await removeDuplicateUsers(filterData);

			await addPictureFromUsers(data);

			for await (const [index, user] of data.entries()) {
				let usersActive = await this.verifyUsersActive(user.usersProfiles);
				data[index].usersProfiles = usersActive;
			}

			this.dataTable = data;
		},

		async clearFilter() {
			this.isLoadingPage = true;

			this.filtering = false;
			this.filtersActive = {};
			this.clearfiltering = false;
			this.dataTable = [];

			await this.$router.replace({'query': null}).catch(() => {});

			WebStorage.removeItem('applyPage');
			this.$store.commit('setFilters', this.filtersActive);
			await this.getAccount();

			this.isLoadingPage = false;
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		limitedUsers(listUsers){
			return listUsers.slice(0,this.numCells);
		},
		goToEditProfile(profileData) {
			this.$router.push({
				name: 'editProfile',
				params: { permissionId: profileData.id },
			}).catch(()=>{});
		},
		updateNumCells() {
			const windowWidth = window.innerWidth;
			const sideMenuSize = !this.isCollapsed ? 213 : 0;

			if (windowWidth >= 1900 + sideMenuSize ) {
				this.numCells = 19;
			} else if (windowWidth >= 1600 + sideMenuSize) {
				this.numCells = 15;
			}	else if (windowWidth >= 1360 + sideMenuSize) {
				this.numCells = 8;
			} else if (windowWidth >= 1060 + sideMenuSize) {
				this.numCells = 5;
			} else {
				this.numCells = 3;
			}
		},
		async loadTable() {
			const payload = { pageSize: this.pageSize, page: 1 };
			this.isLoadingTable = true;
			const response = await this.getProfiles(payload);

			const data = await removeDuplicateUsers(response);
			await addPictureFromUsers(data);
			for await (const [index, user] of data.entries()) {
				let usersActive = await this.verifyUsersActive(user.usersProfiles);
				data[index].usersProfiles = usersActive;
			}

			this.dataTable = data;

			this.isLoadingTable = false;
		},

		async verifyUsersActive(users) {
			if (!users) return;
			const vm = this;
			let usersAble = [];
			for await (const user of users) {
				if (vm.usersActive.find(userActive => userActive.tenantUserId == user.tenantUserId)) {
					usersAble.push(user);
				} else {
					await this.verifyUserActive({userId: user.userId}).then((response) => {
						if (response) {
							let indexUser = users.findIndex(userTable => userTable.id === user.id);
							usersAble.push(users[indexUser]);
							this.usersActive.push(users[indexUser]);
						}
					});
				}
			};


			return usersAble;
		},
		async pagination() {
			this.page++;
			const payload = { page: this.page };
			await this.getProfiles(payload).then(async (response) => {
				for (const index in response) {
					this.dataTable.push(response[index]);
				}
				profileData.data.forEach((profile) => {
					let dataProfile = this.dataTable.find(dataProfile => dataProfile.id == profile.id)
					if (dataProfile) {
						dataProfile.statusActive = profile.active;
						dataProfile.solutionId = profile.solution.id;
					};
				});

				// Filtra pelo solutionId
				const filterData = this.dataTable.filter(item => item.solutionId == 'aca76e07-280f-4e1f-a4df-f8a94757e0fc')

				let data = await removeDuplicateUsers(filterData);

				await addPictureFromUsers(data);

				for await (const [index, user] of data.entries()) {
					let usersActive = await this.verifyUsersActive(user.usersProfiles);
					data[index].usersProfiles = usersActive;
				}

				this.dataTable = data;

				if (response.length < 10) {
					this.scrollInfinite = false;
				}
			});
		},
		async getAccount() {
			const payload = { ...this.$route.query, pageSize: this.pageSize, page: 1 };
			const response = await this.getProfiles(payload);
			let profileData = await this.getProfilesByTenantIdV2();

			profileData.data.forEach((profile) => {
				let dataProfile = response.find(dataProfile => dataProfile.id == profile.id)
				if (dataProfile) {
					dataProfile.statusActive = profile.active;
					dataProfile.solutionId = profile.solution.id;
				};
			});

			// Filtra pelo solutionId
			const filterData = response.filter(item => item.solutionId == 'aca76e07-280f-4e1f-a4df-f8a94757e0fc')


			let data = await removeDuplicateUsers(filterData);

			await addPictureFromUsers(data);

			for await (const [index, user] of data.entries()) {
				let usersActive = await this.verifyUsersActive(user.usersProfiles);
				data[index].usersProfiles = usersActive;
			}

			this.dataTable = data;

			if (this.dataTable.length < 10) {
				this.scrollInfinite = false;
			}
			this.dataTableTemp = this.dataTable;

		},
		goToProfileDetailing(permissionId) {
			this.$router.push({
				name: 'profileDetailing',
				params: { permissionId },
			}).catch(()=>{});
		},

		openAlertModal(profile) {
			this.currentProfile = profile;
			this.showAlert = true;
		},

		async deletProfile(profile) {
			this.isLoadingAction = true;

			try {
				await this.deleteProductProfile(profile.id);

				const index = this.dataTable.findIndex(item => item.productProfile.id === profile.id);
				if (index !== -1) {
					this.dataTable.splice(index, 1);
				}

				this.$store.commit('activeAlert', {
					message: `O perfil ${profile.description} foi excluído com sucesso`,
				});

				this.showAlert = false;
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: `Falha ao excluir o perfil ${profile.description}, tente novamente`,
					status: 'error',
				});
			}

			this.isLoadingAction = false;
		},
	},
	watch: {
		isCollapsed(newValue) {
			this.updateNumCells();
		},
	},
	beforeDestroy() {
		this.$root.$off("reloadList");
		window.removeEventListener('resize', this.updateNumCells);
	},
});
</script>

<style lang="scss" scoped>

.profile_tableHeaderAux {
	z-index: 3;
}
.users__Column_Frame {
	text-align: center;
	border: 1px solid #34476a;
	border-radius: 100px;
	padding: 4px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}
.users_Active {
	color: #00C0FF;
}

.users_Confirmed {
	color: #00C0FF;
	font-size: 12px;
}

.users_Inactive {
	color: #ACD7FF;
}


.profile_table {
	padding: 0 1.5%;
	margin-bottom: 1.5%;
	position: relative;
}

.profile_tableProfileName {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #59d64c;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		color: #59d64c;
	}
}

.profile_tablePictures {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: rgba(255, 255, 255, 0.88);
}

.profile_tableActionsCenter {
	.profile_tableActionsFrame {
		display: flex;
		justify-content: center;

		.profile_tableActionsButtonLeft {
			width: 32px;
			height: 32px;
			border: 1px solid #4a638f;
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24);
			cursor: pointer;

			&:hover {
				position: relative;
				background-color: #29364f;

				.tooltipAliare {
					display: flex;
				}

				.tooltipAliareDisable {
					z-index: 9999;
					width: fit-content;
				}
			}
			&.eyeButton {
				border-radius: 4px 0 0 4px;
				background-position: center;
				background-size: 18px 12.27px;
				background-repeat: no-repeat;
				background-image: url('../assets/blueEyeIcon.svg');

				&--disableEye {
					cursor: not-allowed;
					background-color: #29364f;
					background-image: url('../assets/blueEyeIconGrey.svg');
				}
			}
			&.editButton {
				border-radius: 4px 0 0 4px;
				background-image: url('../assets/pen-blue.svg');
				background-position: center;
				background-size: 18px 12.27px;
				background-repeat: no-repeat;
				border-radius: 0;

				&--disableEdit {
					cursor: not-allowed;
					background-color: #29364f;
					background-image: url('../assets/pen.svg');
				}
			}
			&.deleteButton {
				border-radius: 0 4px 4px 0;
				background-position: center;
				background-size: 12.44px 16px;
				background-repeat: no-repeat;
				background-image: url('../assets/delete.svg');

				&--disableDelete {
					cursor: not-allowed;
					background-color: #29364f;
					background-image: url('../assets/deleteGrey.svg');
				}
				&--activeProfile {
					background-size: 16px;
					background-image: url('../assets/activeIcon.svg');
				}
			}
		}
	}
}

:deep {
	.header__header .header__headerSearch .header__headerRegisterVariableButton {
		min-width: 156px;
	}

	.loadingScreen{
		z-index: 0;
	}
}
</style>
