import { access } from "fs";

export const MAX_DISPLAY_DATA_SIZE = 204800;
export const MAX_DISPLAY_ITEMS_AUTO_ALL = 250;
export const NODE_NAME_PREFIX = 'node-';

export const PLACEHOLDER_FILLED_AT_EXECUTION_TIME = '[filled at execution time]';

// workflows
export const PLACEHOLDER_EMPTY_WORKFLOW_ID = '__EMPTY__';
export const DEFAULT_NODETYPE_VERSION = 1;
export const DEFAULT_NEW_WORKFLOW_NAME = 'Novo Fluxo';
export const MIN_WORKFLOW_NAME_LENGTH = 1;
export const MAX_WORKFLOW_NAME_LENGTH = 128;
export const DUPLICATE_POSTFFIX = ' copy';
export const NODE_OUTPUT_DEFAULT_KEY = '_NODE_OUTPUT_DEFAULT_KEY_';

// tags
export const MAX_TAG_NAME_LENGTH = 24;

// modals
export const DUPLICATE_MODAL_KEY = 'duplicate';
export const DUPLICATE_WORKFLOW_MODAL_KEY = 'duplicateWorkflow';
export const TAGS_MANAGER_MODAL_KEY = 'tagsManager';
export const WORKFLOW_OPEN_MODAL_KEY = 'workflowOpen';
export const WORKFLOW_SETTINGS_MODAL_KEY = 'settings';
export const CREDENTIAL_EDIT_MODAL_KEY = 'editCredential';
export const CREDENTIAL_SELECT_MODAL_KEY = 'selectCredential';
export const TIMEZONE_SELECT_MODAL_KEY = 'selectTimezone';
export const RETRY_EXECUTION_MODAL_KEY = 'retryExecution';
export const EXECUTIONS_LIST_MODAL_KEY = 'executionList';
export const EXECUTION_QUEUE_RESOURCE_MODAL_KEY = 'executionQueueModal';
export const RESULT_EXECUTION_MODAL_KEY = 'resultExecution';
export const TIMEOUT_EXECUTION_MODAL_KEY = 'executionTimeout';
export const ATTACHED_WORKFLOW_MODAL_KEY = 'attachedWorkflow';
export const ATTACHED_EXECUTION_MODAL_KEY = 'attachedExecution';
export const SELECT_NODE_MODAL_KEY = 'selectNode';
export const SHARE_MODAL_KEY = 'share';
export const NOTIFICATION_MODAL_KEY = 'notification';
export const VALIDATION_RESOURCE_MODAL_KEY = 'resourceDataCenter';
export const DEFINE_FAILURE_FLOW_MODAL_KEY = 'failureFlow';
export const CREATE_EQUIVALENCE_OPTIONS_MODAL = 'createEquivalenceOptions';
export const DUPLICATE_PROJECT_MODAL_KEY = 'duplicateProject';
export const FILTER_PANEL_MODAL_KEY = 'filterPanel';
export const HISTORY_VERSION_MODAL_KEY = 'historyVersion';
export const CREDENTIAL_LIST_MODAL_KEY = 'credentialsList';
export const PERSONALIZATION_MODAL_KEY = 'personalization';
export const CONTACT_PROMPT_MODAL_KEY = 'contactPrompt';
export const VALUE_SURVEY_MODAL_KEY = 'valueSurvey';
export const EXECUTIONS_MODAL_KEY = 'executions';
export const WORKFLOW_ACTIVE_MODAL_KEY = 'activation';
export const MANAGER_VARIABLE_MODAL_KEY = 'manageVariable';
export const RENAME_WORKFLOW_MODAL_KEY = 'renameWorkflow';
export const CREATE_EDIT_PROJECT_MODAL_KEY = 'createEditProject';
export const INACTIVE_USER_MODAL_KEY = 'inactiveUserModal';
export const MODALS_REGISTER_USER_MODAL_KEY = 'modalsRegisterUser';
export const IMPORT_INTEGRATION_MODAL_KEY = 'importIntegration';

// breakpoints
export const BREAKPOINT_SM = 768;
export const BREAKPOINT_MD = 992;
export const BREAKPOINT_LG = 1200;
export const BREAKPOINT_XL = 1920;


export const N8N_IO_BASE_URL = `https://api.n8n.io/`;

// node types
export const CALENDLY_TRIGGER_NODE_TYPE = 'n8n-nodes-base.calendlyTrigger';
export const CRON_NODE_TYPE = 'n8n-nodes-base.cron';
export const CLEARBIT_NODE_TYPE = 'n8n-nodes-base.clearbit';
export const FUNCTION_NODE_TYPE = 'n8n-nodes-base.function';
export const GITHUB_TRIGGER_NODE_TYPE = 'n8n-nodes-base.githubTrigger';
export const ERROR_TRIGGER_NODE_TYPE = 'n8n-nodes-base.errorTrigger';
export const ELASTIC_SECURITY_NODE_TYPE = 'n8n-nodes-base.elasticSecurity';
export const EMAIL_SEND_NODE_TYPE = 'n8n-nodes-base.emailSend';
export const EXECUTE_COMMAND_NODE_TYPE = 'n8n-nodes-base.executeCommand';
export const HTTP_REQUEST_NODE_TYPE = 'n8n-nodes-base.httpRequest';
export const IF_NODE_TYPE = 'n8n-nodes-base.if';
export const ITEM_LISTS_NODE_TYPE = 'n8n-nodes-base.itemLists';
export const JIRA_TRIGGER_NODE_TYPE = 'n8n-nodes-base.jiraTrigger';
export const MICROSOFT_EXCEL_NODE_TYPE = 'n8n-nodes-base.microsoftExcel';
export const MICROSOFT_TEAMS_NODE_TYPE = 'n8n-nodes-base.microsoftTeams';
export const NO_OP_NODE_TYPE = 'n8n-nodes-base.noOp';
export const PAGERDUTY_NODE_TYPE = 'n8n-nodes-base.pagerDuty';
export const SALESFORCE_NODE_TYPE = 'n8n-nodes-base.salesforce';
export const SEGMENT_NODE_TYPE = 'n8n-nodes-base.segment';
export const SET_NODE_TYPE = 'n8n-nodes-base.set';
export const SLACK_NODE_TYPE = 'n8n-nodes-base.slack';
export const SPREADSHEET_FILE_NODE_TYPE = 'n8n-nodes-base.spreadsheetFile';
export const START_NODE_TYPE = 'n8n-nodes-base.start';
export const SWITCH_NODE_TYPE = 'n8n-nodes-base.switch';
export const QUICKBOOKS_NODE_TYPE = 'n8n-nodes-base.quickbooks';
export const WEBHOOK_NODE_TYPE = 'n8n-nodes-base.webhook';
export const XERO_NODE_TYPE = 'n8n-nodes-base.xero';

// Node creator
export const CORE_NODES_CATEGORY = 'Core Nodes';
export const CUSTOM_NODES_CATEGORY = 'Custom Nodes';
export const SUBCATEGORY_DESCRIPTIONS: {
	[category: string]: { [subcategory: string]: string };
} = {
	'Core Nodes': { // this - all subkeys are set from codex
		Flow: 'Branches, core triggers, merge data',
		Files:  'Work with CSV, XML, text, images etc.',
		'Data Transformation': 'Manipulate data fields, run code',
		Helpers: 'HTTP Requests (API calls), date and time, scrape HTML',
		//Tools: "Ferramentas",
	},
	'Data & Storage': {
		Database: 'Database',
	},
	'Agribusiness': {
		'Digital Agriculture': 'Agricultura Digital',
		TMS: 'TMS',
		'Sales & Fidelity': 'Vendas e Fidelidade',
		ERP: 'ERPs',
		fuelControl: 'Controle de Combustível',
		cropMonitoring: 'Monitoramento de lavoura',
		CRM: 'CRM',
		machineMonitoring: 'Monitoramento de máquina',
		marketplaceAndCustomerLoyalty: 'Marketplace e fidelização de cliente',
		wms: 'WMS',
		expenseManagement: 'Gestão de despesa',
		contractManagement:'Gestão de Contratos',
		dms: "DMS"
	},
};
export const REGULAR_NODE_FILTER = 'Regular';
export const TRIGGER_NODE_FILTER = 'Trigger';
export const ALL_NODE_FILTER = 'All';
export const UNCATEGORIZED_CATEGORY = 'Miscellaneous';
export const UNCATEGORIZED_SUBCATEGORY = 'Others';
export const PERSONALIZED_CATEGORY = 'Suggested Nodes';
export const HIDDEN_NODES = [START_NODE_TYPE];

export const REQUEST_NODE_FORM_URL = 'https://n8n-community.typeform.com/to/K1fBVTZ3';

// General
export const INSTANCE_ID_HEADER = 'n8n-instance-id';
export const WAIT_TIME_UNLIMITED = '3000-01-01T00:00:00.000Z';

export const WORK_AREA_KEY = 'workArea';
export const FINANCE_WORK_AREA = 'finance';
export const HR_WORK_AREA = 'HR';
export const IT_ENGINEERING_WORK_AREA = 'IT-Engineering';
export const LEGAL_WORK_AREA = 'legal';
export const MARKETING_WORK_AREA = 'marketing-growth';
export const PRODUCT_WORK_AREA = 'product';
export const SALES_BUSINESSDEV_WORK_AREA = 'sales-businessDevelopment';
export const SECURITY_WORK_AREA = 'security';
export const SUPPORT_WORK_AREA = 'support';
export const OPS_WORK_AREA = 'ops';
export const EXECUTIVE_WORK_AREA = 'executive';
export const OTHER_WORK_AREA_OPTION = 'other';
export const NOT_APPLICABLE_WORK_AREA = 'n/a';

export const COMPANY_INDUSTRY_KEY = 'companyIndustry';
export const E_COMMERCE_INDUSTRY = 'e-commerce';
export const AUTOMATION_CONSULTING_INDUSTRY = 'automation-consulting';
export const SYSTEM_INTEGRATION_INDUSTRY = 'systems-integration';
export const GOVERNMENT_INDUSTRY = 'government';
export const LEGAL_INDUSTRY = 'legal-industry';
export const HEALTHCARE_INDUSTRY= 'healthcare';
export const FINANCE_INDUSTRY = 'finance-industry';
export const SECURITY_INDUSTRY = 'security-industry';
export const SAAS_INDUSTRY = 'saas';
export const OTHER_INDUSTRY_OPTION= 'other';

export const COMPANY_SIZE_KEY = 'companySize';
export const COMPANY_SIZE_20_OR_LESS = '<20';
export const COMPANY_SIZE_20_99 = '20-99';
export const COMPANY_SIZE_100_499 = '100-499';
export const COMPANY_SIZE_500_999 = '500-999';
export const COMPANY_SIZE_1000_OR_MORE = '1000+';
export const COMPANY_SIZE_PERSONAL_USE = 'personalUser';

export const CODING_SKILL_KEY = 'codingSkill';
export const OTHER_WORK_AREA_KEY = 'otherWorkArea';
export const OTHER_COMPANY_INDUSTRY_KEY = 'otherCompanyIndustry';

export const MODAL_CANCEL = 'cancel';
export const MODAL_CLOSE = 'close';
export const MODAL_CONFIRMED = 'confirmed';

export const VALID_EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const LOCAL_STORAGE_ACTIVATION_FLAG = 'N8N_HIDE_ACTIVATION_ALERT';

export const TEMPLATES_NODES_FILTER = [
	'n8n-nodes-base.start',
	'n8n-nodes-base.respondToWebhook',
];

export const TECHNICAL_DOCUMENTATION = 'https://atendimento.siagri.com.br/kb/article/406769/ola-que-bom-te-ver-por-aqui';
export const OPERATION_TENANT = 'fafca64f-a53b-478e-90b2-98f0e8e95cac';
export const OPERATION_PROFILE = 'Operação Aliare Integra';

//DatePicker

export const TIME_RANGE = {
	0: "00:00",
	1: "00:30",
	2: "01:00",
	3: "01:30",
	4: "02:00",
	5: "02:30",
	6: "03:00",
	7: "03:30",
	8: "04:00",
	9: "04:30",
	10: "05:00",
	11: "05:30",
	12: "06:00",
	13: "06:30",
	14: "07:00",
	15: "07:30",
	16: "08:00",
	17: "08:30",
	18: "09:00",
	19: "09:30",
	20: "10:00",
	21: "10:30",
	22: "11:00",
	23: "11:30",
	24: "12:00",
	25: "12:30",
	26: "13:00",
	27: "13:30",
	28: "14:00",
	29: "14:30",
	30: "15:00",
	31: "15:30",
	32: "16:00",
	33: "16:30",
	34: "17:00",
	35: "17:30",
	36: "18:00",
	37: "18:30",
	38: "19:00",
	39: "19:30",
	40: "20:00",
	41: "20:30",
	42: "21:00",
	43: "21:30",
	44: "22:00",
	45: "22:30",
	46: "23:00",
	47: "23:30",
	48: "24:00",
};

//PermissionsIds

export const PERMISSION_IDS = {
	//Gerir Integrações
	manageIntegrationId: 'Gerir integrações',
	//Gerir Projetos
	manageProjectId: 'Gerir projetos',
	//Projetos
	editProjectId: 'Editar projeto',
	createProjectId: 'Criar projeto',
	deleteProjectId: 'Deletar projeto',
	duplicateProjectId: 'Duplicar projeto',
	shareProjectId: 'Compartilhar projeto',

	//Fluxos
	openFlowId: 'Abrir fluxo',
	createFlowId: 'Criar fluxo',
	duplicateFlowId: 'Duplicar fluxo',
	deleteFlowId: 'Excluir fluxo',
	editFlowId: 'Editar fluxo',

	//Gerir Equivalencias
	manageEquivalencesId: 'Gerir equivalências',
	//Equivalencias
	registerEquivalenceId: 'Cadastrar equivalência',
	deleteEquivalenceId: 'Excluir equivalência',
	shareEquivalenceId: 'Compartilhar equivalência',
	editEquivalenceId: 'Editar equivalência',

	//Fonte de Dados
	registerDataSourceId: 'Cadastrar fonte de dados',
	editDataSourceId: 'Editar fonte de dados',
	deleteDataSourceId: 'Excluir fonte de dados',
	activeInactiveDataSourceId: 'Ativar/Inativar fonte de dados',

	//Gerir Variaveis
	manageVariableId: 'Gerir variáveis',
	//Variaveis
	createVariableId: 'Cadastrar variável',
	editVariableId: 'Editar variável',
	deleteVariableId: 'Excluir variável',

	//Gerir Credenciais
	manageCredentialsId: 'Gerir credenciais',
	//Credenciais
	createCredentialId: 'Cadastrar credencial',
	editCredentialId: 'Editar credencial',
	deleteCredentialId: 'Excluir credencial',
	shareCredentialId: 'Compartilhar credencial',

	//Gerir Logs
	manageLogs: 'Gerir logs',
	//Logs de Execuções
	acessExecutionsId: 'Acessar execuções',
	reprocessExecutionId: 'Reprocessar execução',

	//Acessar Painel de Integrações
	accessIntegrationPanel: 'Painel de Integrações',

	// Monitoramento do Painel de Integrações
	adminIntegrationPanel: 'Monitoramento de execuções',

	//Gerir Equipes
	manageTeamsId: 'Gerir equipes',
	//Gerir usuários
	manageUsersId: 'Gerir usuários',
	//Usuários
	registerUserId: 'Cadastrar usuário',
	editUserId: 'Editar usuário',
	activeInactiveUserId: 'Ativar/Desativar usuário',

	//Gerir perfis de usuários
	manageUserProfileId: 'Gerir perfis de usuários',
	//Perfis de Usuários
	registerUserProfileId: 'Cadastrar perfil de usuários',
	editUserProfileId: 'Editar perfil de usuários',
	activeInactiveUserProfileId: 'Ativar/Inativar perfil de usuários',

	//Gerir perfis de usuários
	manageWorkspaceId: 'Gerir espaço de trabalho',
	//Perfis de Usuários
	createWorkspaceId: 'Criar espaço de trabalho',
	editWorkspaceId: 'Editar espaço de trabalho',
	deleteWorkspaceId: 'Excluir espaço de trabalho',

	//Fila de Execuções
	manageExecutionQueue: 'Gerir fila de execuções',
	stopExecution: 'Parar execução',

	//Loja de integrações
	manageIntegrationsStore: 'Gerir catálogo',
	accessStore: 'Catálogo de integrações',
	requestIntegration: 'Solicitar integração',
	importIntegration: 'Importar integração',
	createIntegrations: 'Criar Integração',
	editeIntegrations: 'Editar Integração',
	deleteIntegrations: 'Excluir Integração',
	toggleStatusIntegration: 'Publicar/Suspender Integração',
	manageRequestIntegrations: 'Gerir solicitações recebidas',
	manageRequest: 'Aprovar/Reprovar solicitação',

	//Monitoramento de Recursos
	stopExecutionResource: 'Cancelar execução de fluxos',
	reloadPod: 'Reiniciar instâncias executoras',
	manageResource: 'Monitoramento de recursos',
};

export const typePerson = [
	{
		id: 1,
		label: 'Física',
	},
	{
		id: 2,
		label: 'Jurídica',
	},
	{
		id: 3,
		label: 'Estrangeira',
	},
];

//Nacionalidades
export const nacionalidades = [
	{ id: 1, label: 'Afeganistão' },
	{ id: 2, label: 'Alemanha' },
	{ id: 4, label: 'Angola' },
	{ id: 5, label: 'Argentina' },
	{ id: 6, label: 'Austrália' },
	{ id: 7, label: 'Áustria' },
	{ id: 8, label: 'Bélgica' },
	{ id: 9, label: 'Bolívia' },
	{ id: 10, label: 'Brasil' },
	{ id: 11, label: 'Bulgária' },
	{ id: 12, label: 'Canadá' },
	{ id: 13, label: 'Chile' },
	{ id: 14, label: 'China' },
	{ id: 15, label: 'Colômbia' },
	{ id: 16, label: 'Coreia do Sul' },
	{ id: 17, label: 'Costa Rica' },
	{ id: 18, label: 'Croácia' },
	{ id: 19, label: 'Cuba' },
	{ id: 20, label: 'Dinamarca' },
	{ id: 21, label: 'República Dominicana' },
	{ id: 22, label: 'Egito' },
	{ id: 23, label: 'Equador' },
	{ id: 24, label: 'Eslováquia' },
	{ id: 25, label: 'Eslovênia' },
	{ id: 26, label: 'Espanha' },
	{ id: 27, label: 'Estados Unidos da América' },
	{ id: 28, label: 'Estônia' },
	{ id: 29, label: 'Etiópia' },
	{ id: 30, label: 'Filipinas' },
	{ id: 31, label: 'Finlândia' },
	{ id: 32, label: 'França' },
	{ id: 33, label: 'Gabão' },
	{ id: 34, label: 'Gana' },
	{ id: 35, label: 'Geórgia' },
	{ id: 36, label: 'Grécia' },
	{ id: 37, label: 'Guatemala' },
	{ id: 38, label: 'Haiti' },
	{ id: 39, label: 'Países Baixos' },
	{ id: 40, label: 'Honduras' },
	{ id: 41, label: 'Hungria' },
	{ id: 42, label: 'Índia' },
	{ id: 43, label: 'Indonésia' },
	{ id: 44, label: 'Reino Unido' },
	{ id: 45, label: 'Irã' },
	{ id: 46, label: 'Iraque' },
	{ id: 47, label: 'Irlanda' },
	{ id: 48, label: 'Islândia' },
	{ id: 49, label: 'Israel' },
	{ id: 50, label: 'Itália' },
	{ id: 51, label: 'Jamaica' },
	{ id: 52, label: 'Japão' },
	{ id: 53, label: 'Jordânia' },
	{ id: 54, label: 'Laos' },
	{ id: 55, label: 'Letônia' },
	{ id: 56, label: 'Líbano' },
	{ id: 57, label: 'Lituânia' },
	{ id: 58, label: 'Luxemburgo' },
	{ id: 59, label: 'Malásia' },
	{ id: 60, label: 'México' },
	{ id: 61, label: 'Moçambique' },
	{ id: 62, label: 'Moldávia' },
	{ id: 63, label: 'Mongólia' },
	{ id: 64, label: 'Montenegro' },
	{ id: 65, label: 'Nepal' },
	{ id: 66, label: 'Nicarágua' },
	{ id: 67, label: 'Nigéria' },
	{ id: 68, label: 'Noruega' },
	{ id: 69, label: 'Nova Zelândia' },
	{ id: 70, label: 'Omã' },
	{ id: 71, label: 'Panamá' },
	{ id: 72, label: 'Paquistão' },
	{ id: 73, label: 'Paraguai' },
	{ id: 74, label: 'Peru' },
	{ id: 75, label: 'Polônia' },
	{ id: 76, label: 'Portugal' },
	{ id: 77, label: 'Quênia' },
	{ id: 78, label: 'Quirguistão' },
	{ id: 79, label: 'Romênia' },
	{ id: 80, label: 'Ruanda' },
	{ id: 81, label: 'Rússia' },
	{ id: 82, label: 'El Salvador' },
	{ id: 83, label: 'Sérvia' },
	{ id: 84, label: 'Singapura' },
	{ id: 85, label: 'Síria' },
	{ id: 86, label: 'Somália' },
	{ id: 87, label: 'África do Sul' },
	{ id: 88, label: 'Sudão' },
	{ id: 89, label: 'Suécia' },
	{ id: 90, label: 'Suíça' },
	{ id: 91, label: 'Suriname' },
	{ id: 92, label: 'Tailândia' },
	{ id: 93, label: 'Taiwan' },
	{ id: 94, label: 'Tanzânia' },
	{ id: 95, label: 'República Tcheca' },
	{ id: 96, label: 'Tunísia' },
	{ id: 97, label: 'Turquia' },
	{ id: 98, label: 'Turcomenistão' },
	{ id: 99, label: 'Ucrânia' },
	{ id: 100, label: 'Uganda' },
	{ id: 101, label: 'Uruguai' },
	{ id: 102, label: 'Uzbequistão' },
	{ id: 103, label: 'Venezuela' },
	{ id: 104, label: 'Vietnã' },
	{ id: 105, label: 'Iêmen' },
	{ id: 106, label: 'Zâmbia' },
	{ id: 107, label: 'Zimbábue' }
];

