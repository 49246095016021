
import { SELECT_NODE_MODAL_KEY } from '../constants';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { genericHelpers } from '@/components/mixins/genericHelpers';

import { mapActions } from 'vuex';
import { VueEditor } from "vue2-editor";
import mixins from 'vue-typed-mixins';

import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import Modal from '@/components/ModalAliare.vue';
import AliareInput from '@/components/AliareInput.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import NodeIcon from '@/components/NodeIcon.vue';
import NotificationPage from '@/components/NotificationPage2.vue';


import { removeItemFromArrayByValue } from '@/components/helpers';
import WebStorage from '@/common/WebStorage';

export default mixins(
	nodeHelpers,
	showMessage,
	genericHelpers,
).extend({
	components: {
		VueEditor,
		BackgroundView,
		ModalButtonCancel,
		ModalButtonSuccess,
		Modal,
		NodeIcon,
		NotificationPage,
		AliareInput,
		ScrollInfinite,
		AlertNotificationModal,
	},
	name: 'createEditIntegration',
	data() {
		return {
			nodeToSelect: 3,
			currentDataCenter: '',
			currentStep: 'select',
			lastSelectedAccountId: '',
			lastSelectedWorkspaceId: '',
			isLoadingPage: true,
			isLoadingProject: '',
			scrollInfinite: true,
			isLoadingAccount: false,
			selectedProjects: [],
			isLoadingWorkspace: '',
			availableWorkspace: '',
			availableProject: '',
			availableAccount: '',
			publishedIntegration: {},
			integrationValidName: '',
			modeAlert: '',
			originalTitleIntegration: '',
			originalTenantId: '',
			form: {
				titulo: '',
				conectores: [],
				tags: [],
				resumo: '',
				detalhes:'',
				visibilidade: 'Publico',
				oldName: '',
				tempKey: '',
				palavrasChave: [],
				imagem: null,
				status: '',
				imagemUrl: '',
			},
			customToolbar: [
				["bold", "italic", "underline"],
				[{ list: "bullet" }],
				["link"],
			],
			page: 1,
			showAlert: false,
			showNotification: false,
			tokenData: WebStorage.getItem('tokenData'),
		};
	},
	computed: {

		integrationEditId() {
			if (this.isEdit) {
				return this.$route.params.id;
			} else {
				return '';
			}
		},

		activeCard() {
			return !!this.form?.titulo || this.nodesOfIntegration.length > 0 || !!this.form?.resumo;
		},
		keepText() {
			return this.isEdit ? this.$locale.baseText('Continuar Edição') : this.$locale.baseText('Continuar Cadastro');
		},
		discardText() {
			return this.isEdit ? this.$locale.baseText('Cancelar Edição') : this.$locale.baseText('Cancelar Cadastro');
		},
		dataCenterId() {
			return this.$route.params.id;
		},
		alertNotificationTitle() {
			return this.isEdit ? 'Cancelamento da Edição' : 'Cancelamento do Cadastro';
		},
		alertNotificationDescription() {
			return 'Todas as parametrizações que estão sendo feitas serão <b>excluídas</b> e você <b>não poderá recuperá-las</b>. O que você deseja fazer?';
		},
		workspace() {
			return this.$store.getters.workspace;
		},
		isEdit() {
			return this.$route.name === 'editIntegration';
		},
		nodesOfIntegration() {
			return this.$store.getters.getNodesIntegration;
		},
		canCreateIntegration() {
			return !!this.form.titulo && !!this.form.resumo && this.nodesOfIntegration.length > 0 && (!!this.integrationValidName || (this.isEdit && this.originalTitleIntegration == this.form.titulo));
		},
	},
	async mounted() {

	},
	async created() {
		this.loadPageCreateIntegration();
	},
	watch: {

	},
	methods: {
		...mapActions('aliareAccount', ['getAccountTenantV2']),
		...mapActions('project', ['getProjectDetails']),
		removeImage() {
			this.form.imagemUrl = null;
			this.form.imagem = null;
		},
		openNodeModal() {
			this.$store.dispatch('ui/openModal', SELECT_NODE_MODAL_KEY);
		},
		verifyTitleIntegration:
			_.debounce(async function (e)  {
				if(this.form.titulo != '' && this.form.titulo !== this.oldName) {

					const nameAvailable = await this.restApi().verifyTitleAvailableIntegration(this.form.titulo);

					if (nameAvailable) {
						this.integrationValidName = true;
					} else {
						this.integrationValidName = false;
					}
				} else if (this.isEdit && this.form.titulo === this.oldName) {
					this.integrationValidName = true;
				} else if (this.form.titulo == '') {
					this.integrationValidName = '';
				};
			},
		500),

		dragOverHandler(event) {
			event.preventDefault();
    },
    dropHandler(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
	handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.imagem = e.target.result;
      };
      reader.readAsDataURL(file);
    },

		addKeyToForm() {
			if (this.form.tempKey == '') return;
			this.form.palavrasChave.push(JSON.parse(JSON.stringify(this.form.tempKey)));
			this.form.tempKey = '';
		},
		removeKeyToForm(key) {
			if (this.verifyKeyAdded(key)) {
				removeItemFromArrayByValue(this.form.palavrasChave, key);
			}
		},
		verifyKeyAdded(key) {
			return this.form.palavrasChave.find(keyName => keyName === key);
		},
		goToIntegrationList() {
			this.$router.push({
				name: 'integrations',
			});
		},
		goToCreateIntegration() {
			if(this.isEdit) {
				this.$router.push({
					name: 'createIntegration',
				});
			} else {
				window.location.reload();
			}
		},
		async createIntegration(options: {publish: Boolean}) {
			this.isLoadingPage = true;
			try {
				const nameOfNodesSelected = this.nodesOfIntegration.map((node) => node.name);
				const payload = {
					titulo: this.form.titulo,
					resumo: this.form.resumo,
					visibilidade: this.form.visibilidade,
					detalhes:this.form.detalhes,
					projetos: this.selectedProjects.map(project => project.id),
					palavrasChave: this.form.palavrasChave,
					imagem: this.form.imagem,
					imagemUrl: this.form.imagemUrl,
					conectores: nameOfNodesSelected,
				};

				const integrationCreated = await this.restApi().createNewIntegration(payload);

				this.publishedIntegration = integrationCreated;

				if (options.publish) {
					await this.restApi().publishIntegration(integrationCreated.id);
					this.showNotification = true;
				} else {
					this.$store.commit('activeAlert', {
						message: 'Sua integração foi configurada e está salva como um rascunho.',
					});

					this.$router.push({
						name: 'integrations',
					});

				}
			} catch(e) {
				this.$store.commit('activeAlert', {
					message: e.response?.data?.notifications,
					status: 'error',
				});
				this.isLoadingPage = false;
			}
		},
		async updateIntegration(options: {publish: Boolean}) {
			this.isLoadingPage = true;
			try {
				const nameOfNodesSelected = this.nodesOfIntegration.map((node) => node.name);

				const payload = {
					titulo: this.form.titulo,
					resumo: this.form.resumo,
					visibilidade: this.form.visibilidade,
					detalhes:this.form.detalhes,
					projetos: this.selectedProjects.map(project => project.id),
					status: this.form.status,
					palavrasChave: this.form.palavrasChave,
					imagem: this.form.imagem,
					imagemUrl: this.form.imagemUrl,
					conectores: nameOfNodesSelected,
				};

				if (payload.imagem == null) {
					delete payload.imagem;
				} else {
					delete payload.imagemUrl;
				}
					
				const integrationModified = await this.restApi().patchIntegration(this.form.id, payload);

				this.publishedIntegration = integrationModified;

				if (options.publish) {
					await this.restApi().publishIntegration(integrationModified.id);
					this.showNotification = true;
				} else {
					this.$store.commit('activeAlert', {
						message: 'Sua integração foi modificada e está salva como um rascunho.',
					});

					this.$router.push({
						name: 'integrations',
					});

				}
			} catch(e) {
				this.$store.commit('activeAlert', {
					message: e.response?.data?.notifications,
					status: 'error',
				});
				this.isLoadingPage = false;
			}
		},

		goToPreviewPage() {
			const nameOfNodesSelected = this.nodesOfIntegration.map((node) => node.name);

			const payload = {
				titulo: this.form.titulo,
				resumo: this.form.resumo,
				visibilidade: this.form.visibilidade,
				detalhes:this.form.detalhes,
				projetos: this.selectedProjects.map(project => project.id),
				palavrasChave: this.form.palavrasChave,
				imagemUrl: this.form.imagem,
				conectores: nameOfNodesSelected,
			};
			WebStorage.setItem('dataPreview', payload);

			const routeData = this.$router.resolve({name: 'integrationPreview'});

			window.open(routeData.href, '_blank');
		},

		changeSelectedVisible(selected) {
			this.form.visibilidade = selected;
		},

		removeNode(node) {
			this.$store.commit('removeNodeFromIntegration', node);
		},

		addToSelectedProject(project) {
			if (this.verifyProjectSelected(project)) {
				return;
			} else {
				this.selectedProjects.push(project);
			}
		},

		goToPage(step) {
			this.currentStep = step;
		},

		removeFromSelected(project) {
			if (this.verifyProjectSelected(project)) {
				removeItemFromArrayByValue(this.selectedProjects, project);
			}
		},

		verifyProjectSelected(verifyProject) {
			return this.selectedProjects.find(project => project.id === verifyProject.id);
		},

		async loadNodeTypes(): Promise<void> {
			const nodeTypes = await this.restApi().getNodeTypes();

			this.$store.commit('setNodeTypes', nodeTypes);
		},

		async loadAvailableProjects(workspace) {

			if (this.lastSelectedWorkspaceId === workspace.id) {
				return;
			} else {
				setupHeaderAliareTenant(this.lastSelectedAccountId);
				WebStorage.setItem('tenantId', this.lastSelectedAccountId);
			}

			this.lastSelectedWorkspaceId = workspace.id;
			this.isLoadingProject = true;
			try {
				const response = await this.getProjectDetails({workspaceId: workspace.id, size: 300, ativo: true });
				this.availableProject = response.data;
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.response?.data?.notifications,
					status: 'error',
				});
			}
			this.isLoadingProject = false;
		},



		async loadAvailableWorkspaces(account) {
			if (this.lastSelectedAccountId !== account.id) {
				WebStorage.setItem('tenantId', account.id);
			} else {
				return;
			}

			this.availableWorkspace = '';
			this.availableProject = '';
			this.lastSelectedWorkspaceId = '';
			this.lastSelectedAccountId = account.id;
			this.isLoadingWorkspace = true;
			try {
				this.availableWorkspace = await this.restApi().getWorkspaces({ page: 1, size: 999999 });
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.response?.data?.notifications,
					status: 'error',
				});
			}
			this.isLoadingWorkspace = false;
		},

		openModalAlert() {

		},

		async loadPageCreateIntegration() {
			this.isLoadingPage = true;
			this.loadNodeTypes();
			this.originalTenantId = await WebStorage.getItem('tenantId');
			this.availableAccount = await JSON.parse(WebStorage.getItem('cardOrganization'));
			if (this.isEdit) {
				await this.loadCurrentIntegration();
			}
			this.isLoadingPage = false;
		},

		async paginations() {
			if (this.tokenData.Level === 'N1' || this.tokenData.Level === 'N0') {
				this.page++;
				this.scrollInfinite = true;
				const payload = { pageSize: 15, page: this.page };

				try {
					const response = await this.getAccountTenantV2(payload);
					const filteredData = response.data.filter(account => account.active && !account.blocked);
					this.availableAccount.push(...filteredData);

					if (response.data.length < 15) {
						this.scrollInfinite = false;
					}
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			} else {
				this.scrollInfinite = false;
			}
		},


		async loadCurrentIntegration() {
			const integration = await this.restApi().getIntegrationById(this.integrationEditId);

			await this.loadNodesOfIntegration(integration?.conectores);

			this.originalTitleIntegration = integration.titulo;
			this.form.titulo = integration.titulo;
			this.form.resumo = integration.resumo;
			this.form.detalhes = integration.detalhes;
			this.form.visibilidade = integration.visibilidade;
			this.form.oldName = integration.titulo;
			this.form.palavrasChave = integration?.palavrasChave || [];
			this.form.imagemUrl = integration.imagemUrl;
			this.form.status = integration.status;
			this.form.id = integration.id;
			this.selectedProjects = integration.projetos;
		},
		async loadNodesOfIntegration(nodes) {
			if (!nodes) return;
			nodes.forEach(node => {
				let nodeData = this.$store.getters.getAllNodeByName(node);
				this.$store.commit('addNodeIntegration', nodeData);
			});
			this.nodeToSelect = nodes.length;
		},

	},
	beforeDestroy() {
		this.$store.commit('clearNodeIntegration');
		WebStorage.setItem('tenantId', this.originalTenantId);
		setupHeaderAliareTenant(this.originalTenantId);
	},
});
