
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { genericHelpers } from '@/components/mixins/genericHelpers';

import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import Table from '@/components/Table.vue';
import FilterTable from '@/components/FilterTable.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import Modal from '@/components/ModalAliare.vue';
import SearchBar from '@/components/SearchBar/SearchBar.vue';
import AliareInput from '@/components/AliareInput.vue';
import EquivalenceFilter from '@/components/EquivalencesFilters.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';


import { removeItemFromArrayByValue, filter } from '@/components/helpers';
import WebStorage from '@/common/WebStorage';
import { equivalencePermission } from '@/components/mixins/permissions/equivalences';

export default mixins(
	nodeHelpers,
	equivalencePermission,
	showMessage,
	genericHelpers,
).extend({
	components: {
		BackgroundView,
		ModalButtonCancel,
		ModalButtonSuccess,
		Table,
		Modal,
		AliareInput,
		AlertNotificationModal,
		SearchBar,
		EquivalenceFilter,
		FilterTable,
	},
	name: 'dataCenterDetailing',
	data() {
		return {
			activeDataCenter: true,
			isSearching: false,
			searchingList: '',
			currentDataCenter: '',
			isLoading: false,
			dataTableValueEquivalence: [],
			dataTableOriginalEquivalence: [],
			dataTableDescriptionEquivalence: [],
			applyedFilters: [],
			modeAlert: '',
			showAlert: false,
			isLoadingAction: false,

			currentDataCenterTemp: [],
			filtering: false,
		};
	},
	computed: {
		keepText() {
			if (this.modeAlert == 'delete') {
				return `Manter Fonte`;
			} else if(this.modeAlert == 'toggleActive') {
				return `${ this.currentDataCenter.ativo ? 'Manter Fonte Ativa' : 'Ativar Fonte de Dados'}`;
			}
		},
		discardText() {
			if (this.modeAlert == 'delete') {
				return `Excluir Fonte`;
			} else if(this.modeAlert == 'toggleActive') {
				return `${ this.currentDataCenter.ativo ? 'Inativar Fonte de Dados' : 'Manter Fonte Inativa'}`;
			}
		},
		dataCenterId() {
			return this.$route.params.id;
		},
		alertNotificationTitle() {
			if (this.modeAlert == 'delete') {
				return `Exclusão da Fonte de Dados`;
			} else if(this.modeAlert == 'toggleActive') {
				return `${this.currentDataCenter.ativo ? 'Inativação' : 'Ativação'} da Fonte de Dados`;
			}
		},
		alertNotificationDescription() {
			if (this.modeAlert == 'delete') {
				return `Excluir a fonte <b>${this.currentDataCenter.titulo}</b> apagará todas as parametrizações feitas e você não poderá recuperá-las. O que deseja fazer?`;
			} else if(this.modeAlert == 'toggleActive') {
				return `${this.currentDataCenter.ativo ? 'Inativar' : 'Ativar'} a fonte <b>${this.currentDataCenter.titulo}</b> a ${this.currentDataCenter.ativo ? 'indisponibilizará' : 'disponibilizará'} no Gerenciamento das Equivalências. O que deseja fazer?`;

			}
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async mounted() {




	},
	async created() {
		await this.loadCurrentDataCenter();
	},
	watch: {

	},
	methods: {
		toggleStatusDataCenter() {
			try {
				this.showAlert = false;
				this.restApi().updateStatusDataCenter(this.workspace.id, this.currentDataCenter.id, {
					ativo: !this.currentDataCenter.ativo,
				});
				this.currentDataCenter.ativo = !this.currentDataCenter.ativo;
				this.$store.commit('activeAlert', {
					message: `A fonte de dados "${this.currentDataCenter.titulo}" foi ${this.currentDataCenter.ativo ? 'Ativada' : 'Inativada'} com sucesso."`,
				});
			} catch (e) {
				console.error(e);
			}
		},
		clearSearching() {
			this.isSearching = false;
		},

		openAlertToggleActiveData() {
			this.modeAlert = 'toggleActive';
			this.showAlert = true;
		},

		async searchRegister(queryInput) {
			this.searchingList = '';
			const payload = {
				Pesquisa: queryInput,
				size: 10,
			};
			try {
				await this.restApi().getEquivalenceRegister(this.workspace.id, this.currentDataCenter.id, payload)
				.then(response => {
					this.isSearching = true;
					this.searchingList = response;
				});
			} catch (e) {
				console.error(e);
			}
		},


		openModalDelete() {
			this.modeAlert = 'delete';
			this.showAlert = true;
		},

		editDataCenter() {
			this.$router.push({
				name: 'editDataCenter',
				params: { id: this.currentDataCenter.id },
			});
		},


		async deleteDataCenter() {
			this.isLoadingAction = true;

			try {
				await this.restApi().deleteDataCenter(this.workspace.id, this.currentDataCenter.id);

				this.showAlert = false;

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('dataCenter.successDelete', { interpolate: { dataCenterDeleted: this.currentDataCenter.titulo }}),
				});

				this.$router.push({
					name: 'dataSource',
				});

			} catch (error) {
				console.error(error);

				return;
			}

			this.isLoadingAction = false;
		},

		date(data) {
			const newDate = new Date(data);
			const novaData = `${newDate.toLocaleDateString()} às ${newDate.toLocaleTimeString()}`;
			return novaData;
		},

		async loadCurrentDataCenter() {
			this.isLoading = true;

			try {
				const currentDataCenter = await this.restApi().getDataCenterById(this.workspace.id, this.dataCenterId);
				this.currentDataCenter = currentDataCenter;
				this.currentDataCenterTemp = this.currentDataCenter;
			} catch (error) {
				console.log(error);
				this.$router.push({
					name: 'equivalences',
				});
				return;
			}

			this.isLoading = false;
		},

	},
	beforeDestroy() {
	},
});
