import { OPERATION_TENANT, OPERATION_PROFILE, ERROR_TRIGGER_NODE_TYPE } from '@/constants';

import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import TokenService from '@/services/token.service';
import { setupHeaderAliareTenant } from '@/config/axiosConfig';

import { INodeUi } from '@/Interface';
import dateformat from 'dateformat';

import { restApi } from '@/components/mixins/restApi';

import WebStorage from '@/common/WebStorage';
import AliareAccountService from '../services/aliareAccount.service';
import WorkspaceService from '../services/workspace.service';

const KEYWORDS_TO_FILTER = ['API', 'OAuth1', 'OAuth2'];

export function convertToDisplayDate (epochTime: number) {
	return dateformat(epochTime, 'yyyy-mm-dd HH:MM:ss');
}

export function convertToHumanReadableDate (epochTime: number) {
	return dateformat(epochTime, 'd mmmm, yyyy @ HH:MM Z');
}

export function removeItemFromArrayByValue(array, value) {
	const index = array.indexOf(value);

	if (index > -1) {
		return array.splice(index, 1);
	} else {
		return array;
	}
}
export function getAppNameFromCredType(name: string) {
	return name.split(' ').filter((word) => !KEYWORDS_TO_FILTER.includes(word)).join(' ');
}

export function capitalized(name: string) {
	const capitalizedFirst = name[0].toUpperCase();
	const rest = name.slice(1);

	return capitalizedFirst + rest;
};

export function getStyleTokenValue(name: string): string {
	const style = getComputedStyle(document.body);
	return style.getPropertyValue(name);
}

export function getTriggerNodeServiceName(nodeName: string) {
	return nodeName.replace(/ trigger/i, '');
}

export function getActivatableTriggerNodes(nodes: INodeUi[]) {
	return nodes.filter((node: INodeUi) => {
		// Error Trigger does not behave like other triggers and workflows using it can not be activated
		return !node.disabled && node.type !== ERROR_TRIGGER_NODE_TYPE;
	});
}

export async function filter(filterData) {
	let filterDate = filterData.applyedFilters.includes('filterByDate') ? filterData.lastFilterDate : { inicio: null, fim: null };
	let filterPrev = filterData.applyedFilters.includes('filterByName') || filterData.applyedFilters.includes('filterById') || filterData.applyedFilters.includes('filterByProductProfile') ? filterData.prevSelectedArray : [];
	let filterResult = filterData.applyedFilters.includes('filterByResult') ? filterData.lastFilterResult : null;
	let filterValueEquivalence = filterData.applyedFilters.includes('filterByValueEquivalence') ? filterData.valoresEquivalente.map(a => a.valorEquivalente) : [];
	let filterDescriptionEquivalence = filterData.applyedFilters.includes('filterByDescriptionEquivalence') ? filterData.descricoes.map(a => a.descricao) : [];
	let filterOriginalEquivalence = filterData.applyedFilters.includes('filterByOriginalEquivalence') ? filterData.valoresOriginais.map(a => a.valorOrigem) : [];
	let filterNameVariable = filterData.applyedFilters.includes('filterByNameVariable') ? filterData.nomeVariaveis.map(a => a.nome) : [];
	let filterValueVariable = filterData.applyedFilters.includes('filterByValueVariable') ? filterData.valoresVariaveis.map(a => a.valor) : [];
	let filterUser = filterData.applyedFilters.includes('filterByUser') ? filterData.usuariosIdsCompartilhado : [];

	if (filterData.currentFilter === 'filterByName') {
		filterPrev = filterData.prevSelectedArray.map(a => a.id);

	} else if (filterData.currentFilter === 'filterByDate') {
		filterDate = {inicio: filterData.start, fim: filterData.end};

	} else if (filterData.currentFilter === 'filterByUser') {
		filterUser = filterData.usuariosIdsCompartilhado.map(a => a.id);

	} else if (filterData.currentFilter === 'filterByResult') {
		filterResult = filterData.resultFilter;

	} else if (filterData.currentFilter === 'filterById') {
		filterPrev = filterData.prevSelectedArray.map(a => a.id);
	}  else if (filterData.currentFilter === 'filterByValueEquivalence') {
		filterValueEquivalence = filterData.valoresEquivalente.map(a => a.valorEquivalente);
	} else if (filterData.currentFilter === 'filterByDescriptionEquivalence') {
		filterDescriptionEquivalence = filterData.descricoes.map(a => a.descricao);
	} else if (filterData.currentFilter === 'filterByOriginalEquivalence') {
		filterOriginalEquivalence = filterData.valoresOriginais.map(a => a.valorOrigem);
	} else if (filterData.currentFilter === 'filterByNameVariable') {
		filterNameVariable = filterData.nomeVariaveis.map(a => a.nome);
	} else if (filterData.currentFilter === 'filterByValueVariable') {
		filterValueVariable = filterData.valoresVariaveis.map(a => a.valor);
	} else if (filterData.currentFilter === 'filterByProductProfile') {
		filterPrev = filterData.prevSelectedArray.map(a => a.productProfile.id);
	}

	const dataFiltered = await filterData.getData({
		DataHoraUltimaAtualizacaoInicio: filterDate.inicio,
		DataHoraUltimaAtualizacaoFim: filterDate.fim,
		inicio: filterDate.inicio,
		fim: filterDate.fim,
		QtdMinimo: filterData.qtdMin,
		QtdMaximo: filterData.qtdMax,
		size: 10,
		identificador: filterPrev,
		id: filterPrev,
		resultado: filterResult,
		ValoresEquivalente: filterValueEquivalence,
		descricoes: filterDescriptionEquivalence,
		valoresDeOrigem: filterOriginalEquivalence,
		nome: filterNameVariable,
		valores: filterValueVariable,
		productProfileId: filterPrev,
		usuariosIdsCompartilhado: filterUser,
	});

	return {
		dataFiltered,
		filterData,
	};
}

export function verifyPermission(id: string, data: any[]) {
	return data?.find(result => result.functionalityId === id && !result.blocked) || null;
}

export function verifyPermissionDescription(description: string, data: any[]) {
	return data?.find(result => result.description === description && !result.blocked) || null;
}

export function hasPermission(id: string, data: any) {
	return data?.some(verify => verify.functionalityId === id && !verify.blocked) || false;
}

export function hasPermissionByDescription(description: string, data: any[]) {
	return data?.some(verify => verify.description === description && !verify.blocked) || false;
}

export async function searchPermissions(userId?: string, tenantId?: string) {
	const payloadPermission = JSON.parse(WebStorage.getItem('payloadPermission')) || {};
	const payload = {
		clientId: userId && tenantId ? 'aliare-integra' : payloadPermission.clientId || '',
		userId: userId || payloadPermission.userId || '',
		tenantId: tenantId || payloadPermission.tenantId || '',
	};

	WebStorage.setItem('payloadPermission', JSON.stringify(payload));

	try {
		const resultPermission = await AliareAccountService.getPermissionFromUser(payload);

		const permissions = resultPermission.data;
		const access: Object[] = [];
		if (permissions) {
			resultPermission.data.forEach(permission => {
				
				if (permission?.userProfile.active && !permission?.userProfile.blocked) {
					WebStorage.removeItem('userPermission');
					access.push(...permission.access)
				}
			});
		}

		if (access.length) {
			WebStorage.setItem('userPermission', JSON.stringify({ access }));
			return true;
		}

	} catch (error) {
		console.error('Error fetching permissions:', error);
		throw error;
	}

	return false;
}
export async function verifyAdminUser() {
	const data = JSON.parse(WebStorage.getItem('accountInfo'));
	const userId = JSON.parse(JSON.stringify(WebStorage.getItem('tokenData')));

	try {	
		if(data.principalAdminUser.userId === userId.sub) {
			return true;
		}

		if (data.adminUsers) {
			for (const adminUser of data.adminUsers) {
				if (adminUser.userId === userId.sub) {
					return true;
				}
			}
	}

		const response = await AliareAccountService.getUserPermission({ userId: userId.sub, tenantId: OPERATION_TENANT });

		if(response.data) {
			for (const product of response.data) {
				if(product.productProfile?.description == OPERATION_PROFILE) {
					return true;
				}
			}
		}

		return false;
	} catch (error) {
		console.error('error: ', error);
	}
}
export function truncate(value, size?) {
	if (!value) return '';
	if (!size) size = 20;
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
};
export function removeDuplicateUsers(array: any) {
	const map = new Map();

	array.forEach(function(item) {
		if (!map.has(item?.productProfile?.description)) {
			map.set(item?.productProfile?.description, item);
		} else {
			const match = map.get(item?.productProfile?.description);
			match.usersProfiles = match?.usersProfiles?.concat(item?.usersProfiles);
		}
	});
	return Array.from(map.values());
}

export async function addPictureFromUsers(array: any) {
	const promises = [];

	for (const obj of array) {
		for (const subObj of obj?.usersProfiles) {
			if (subObj?.name !== undefined && subObj?.picture !== undefined) {
				continue;
			}

			const promise = (async () => {
				const userInfo = subObj?.userId && await AliareAccountService.getUserId(subObj?.userId);
				return { name: userInfo?.person, picture: userInfo?.viewLinkPicture };
			})();

			promises.push(promise);
		}
	}
	const results = await Promise.all(promises);
	let resultIndex = 0;

	for (const obj of array) {
		for (const subObj of obj?.usersProfiles) {
			if (subObj?.name !== undefined && subObj?.picture !== undefined) {
				continue;
			}

			const { name, picture } = results[resultIndex];
			subObj.name = subObj?.name ?? name;
			subObj.picture = subObj?.picture ?? picture;

			resultIndex++;
		}
	}
	return array;
}

export function isValidCPF(identification: string): boolean {
	const digits = identification.replace(/\D/g, '');

	if (digits.length !== 11) {
		return false;
	}

	const calculateDigit = (slice: string, factor: number) => {
		let sum = 0;
		for (let i = 0; i < slice.length; i++) {
			sum += parseInt(slice.charAt(i)) * (factor - i);
		}
		const rest = sum % 11;
		return rest < 2 ? 0 : 11 - rest;
	};

	const digit1 = calculateDigit(digits.slice(0, 9), 10);
	const digit2 = calculateDigit(digits.slice(0, 10), 11);

	return parseInt(digits.charAt(9)) === digit1 && parseInt(digits.charAt(10)) === digit2;
}

export function isValidCNPJ(cnpj: string) {
	const cleanCNPJ = cnpj.replace(/\D/g, '');

	if (cleanCNPJ.length !== 14) {
		return false; // O CNPJ deve ter 14 dígitos
	}

	const digits = cleanCNPJ.split('').map(Number);
	const [d1, d2] = digits.slice(12); // Últimos dois dígitos do CNPJ

	const calculateDigit = (sliceStart: number, weightStart: number) => {
		let sum = 0;
		let weight = weightStart;
		for (let i = sliceStart; i >= 0; i--) {
			sum += digits[i] * weight;
			weight = (weight === 9) ? 2 : weight + 1;
		}
		const remainder = sum % 11;
		return (remainder < 2) ? 0 : 11 - remainder;
	};

	return d1 === calculateDigit(11, 2) && d2 === calculateDigit(12, 2);
}

export function formatIdentification(identification: string) {

  let x = identification?.replace(/\D/g, '')?.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
  identification = !x[2] ? x[1] : x[1] + '.' + x[2] + (x[3] ? '.' : '') + x[3] + (x[4] ? '/' : x[4]) + x[4] + (x[5] ? '-' + x[5] : '');

  if (identification?.length < 15) {
    x = identification?.replace(/\D/g, '')?.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
    identification = !x[2] ? x[1] : x[1] + '.' + x[2] + (x[3] ? '.' : '') + x[3] + (x[4] ? '-' + x[4] : '');
  }

	return identification;
}

export function formatPhoneFixed(phone: any) {
	phone = phone?.replace(/\D/g, '');
	if (!phone?.startsWith('55')) {
		phone = `55${phone}`;
	}

	if (phone.length > 2) {
		// Formatação para telefone fixo
		return `+${phone.slice(0, 2)} (${phone.slice(2, 4)}) ${phone.slice(4, 8)}-${phone.slice(8)}`;
	}
}

export function formatPhoneMobile(phone: any) {
	phone = phone?.replace(/\D/g, '');
	if (!phone?.startsWith('55')) {
		phone = `55${phone}`;
	}

	if (phone.length > 2) {
		// Formatação para telefone celular
		return `+${phone.slice(0, 2)} (${phone.slice(2, 4)}) ${phone.slice(4, 9)}-${phone.slice(9)}`;
	}
}


export async function initializeFirebaseMessaging() {

  const firebaseConfig = {
    apiKey: 'AIzaSyA7CpalKwxhfFSO6P3mXnhlZCHEYif0ILQ',
    authDomain: 'aliareservices-notificationhub.firebaseapp.com',
    projectId: 'aliareservices-notificationhub',
    storageBucket: 'aliareservices-notificationhub.appspot.com',
    messagingSenderId: '381812158938',
    appId: '1:381812158938:web:b78cf4823c24af3402d0e3',
  };

  const firebaseApp =  initializeApp(firebaseConfig);
  const firebaseMessaging =  getMessaging(firebaseApp);

	Notification.requestPermission().then(permission => {
		if (permission === 'granted') {
			window.dispatchEvent(new CustomEvent('notificationPermissionChanged'));
			getTokenNotification();
			getMessageFromDb();
		}
	});

	function getTokenNotification() {
		getToken(firebaseMessaging, {
			vapidKey: 'BMQbypNB9WDSG9XzB28JpNhEjK2F4gscpc4GquVu1Rz-PuN5mit8l0uGOODERyCj-eCLOuIVNFDCe4lsA1QBLjI',
		}).then(token => {
			WebStorage.setItem('tokenNotification', token);
			subscribeDeviceToken();
		}).catch((e) => {
			setTimeout(() => {
				getTokenNotification();
			}, 2000);
		});
	}

  // Listener que recebe notificações quando a tela está em foco
  onMessage(firebaseMessaging, payload => {
		getMessageFromDb();
  });

}
export async function getMessageFromDb() {
	window.localStorage.removeItem('newNotificationData');

	const hasToken = TokenService.getStoredAccessToken();
	const loggedIn = Boolean(hasToken);
	const clientData = JSON.parse(WebStorage.getItem('payloadPermission'));

	if (!loggedIn || !clientData) return;

	const pastMessage = await fetch(`https://notification-hub.uat.conexa.com.br/api/Push/List?UsuarioId=${clientData.userId}&Status=4`, {
		method: 'GET',
		headers: {
			'Content-Type': "application/json",
			Authorization: `Bearer ${TokenService.getTokens().access_token}`,
			'X-Tenant': WebStorage.getItem("tenantId"),
		},
	});

	const newMessage = await fetch(`https://notification-hub.uat.conexa.com.br/api/Push/List?UsuarioId=${clientData.userId}&Status=2`, {
		method: 'GET',
		headers: {
			'Content-Type': "application/json",
			Authorization: `Bearer ${TokenService.getTokens().access_token}`,
			'X-Tenant': WebStorage.getItem("tenantId"),
		},
	});

	await pastMessage.json().then((dataOld) => {
		WebStorage.saveOldNotificationToLocalStorage(dataOld.data);
	});
	await newMessage.json().then((dataNew) => {
		WebStorage.saveNewNotificationToLocalStorage(dataNew.data);
	});
}

export async function subscribeDeviceToken() {
	try {

		const deviceToken = WebStorage.getItem('tokenNotification');
		const token = TokenService.getTokens();
		const clientData = JSON.parse(WebStorage.getItem('payloadPermission'));
		if (!deviceToken || !token || !clientData) return;


		// Verifica se o token já foi registrado
		const result = await fetch('https://notification-hub.uat.conexa.com.br/api/DeviceToken', {
			method: 'GET',
			headers: {
				'Content-Type': "application/json",
				Authorization: `Bearer ${token.access_token}`,
				'X-Tenant': WebStorage.getItem("tenantId"),
			},
		}).catch(e => {
			return;
		});


		const { data } = await result.json();
		const userDeviceToken = data.find(x => x.token === deviceToken);

		if (userDeviceToken) return;

		const payload = {
			userId: clientData.userId,
			userName: clientData.userId,
			type: 'PUSH',
			device: 'Web Browser',
			product: 'aliare-integra', // Aqui deve ser o clientId do produto. O mesmo cadastrado na Plataforma Aliare
			token: deviceToken,
		};

		// Registra o token
		await fetch('https://notification-hub.uat.conexa.com.br/api/DeviceToken', {
			method: 'POST',
			headers: {
				'Content-Type': "application/json",
				Authorization: `Bearer ${TokenService.getTokens().access_token}`,
				'X-Tenant': WebStorage.getItem("tenantId"),
			},
			body: JSON.stringify(payload),
		})

	} catch (error) {
		console.error(error);
	}
}

export function createNewAccount(data: any) {
	const newAccount = {
		name: data?.account?.accountName || data?.accountName || data?.description,
		tenantId: data?.account?.tenantId || data?.tenantId || data?.id,
		userId: data?.account?.userId || data?.userId|| '',
		owner: data?.owner || '',
	};

	return newAccount;
}

export async function validateWorkspace(context, storedOrganizationId,workspaceId) {
	try {
		setupHeaderAliareTenant(storedOrganizationId);
		
		const { data } = await WorkspaceService.getWorkspaceById(workspaceId);
	
		WebStorage.setItem('workspace', JSON.stringify(data));
		context.$store.commit('setWorkspace', data);

		return true;
	} catch (e) {
		return false;
	}
}

export async function validateCredentials(context, organizationId, workspaceId, toRoute) {
	let storedOrganizationId = WebStorage.getItem('tenantId');
	let storedWorkspaceId = JSON.parse(WebStorage.getItem('workspace'))?.id;
	const publicPages = ['/login', '/recoverpassword'];
	const organizationPages = ['/organizacao', '/mudanca-de-organizacao'];
	const authRequired = !publicPages.includes(toRoute.path);
	const organizationPage = organizationPages.includes(toRoute.path);
	const workspacePage = toRoute.name == 'workspace';
	const uuidv4Regex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;
	const isFirstTime = WebStorage.getItem('isFirstTime');
	const hasToken = TokenService.getStoredAccessToken();
	const loggedIn = Boolean(hasToken);


	if (organizationId === storedOrganizationId && workspaceId === storedWorkspaceId || !organizationId && isFirstTime === null || authRequired && !loggedIn || organizationPage || workspacePage) {
		return true;
	}
	if (organizationId !== storedOrganizationId) {
		if (uuidv4Regex.test(organizationId)) {
			context.$store.commit('activeLoadingCredentials', {showLoading: true})
			
			const tenantData = await AliareAccountService.getAccountDataV2(organizationId);

			if (tenantData.data == '') {
				context.$store.commit('activeAlert', {
					message: 'A organização que você está tentando acessar não existe ou você não possui acesso.',
					status: 'error',
				});
				context.$store.commit('activeLoadingCredentials', {showLoading: false})
				return;
			}

			let accountInfo;
			let validate;

			await Promise.all([
				await createNewAccount(tenantData.data),
			]).then(([accountData]) => {
				accountInfo = accountData;
			}).catch((err) => {
				context.$store.commit('activeLoadingCredentials', {showLoading: false})

				return;
			});

			accountInfo = {
				...accountInfo,
				principalAdminUser: tenantData.principalAdminUser,
				adminUsers: tenantData.adminUsers,
			}
		
			setupHeaderAliareTenant(tenantData.data.id);
			WebStorage.setItem('tenantId', tenantData.data.id);
			WebStorage.changeAccountName(tenantData.data.description);
			WebStorage.setItem('accountInfo', JSON.stringify(accountInfo));
			WebStorage.setItem('isFirstTime', 'false');
			context.$root.$emit("reloadHeader");

			if (workspaceId !== storedWorkspaceId) {
				if (uuidv4Regex.test(workspaceId)) {
					validate = await validateWorkspace(context, tenantData.data.id, workspaceId);

					if (!validate) return;
				} else {
					context.$store.commit('activeLoadingCredentials', {showLoading: false})
					return;
				}
			}

			if (!tenantData || !validate) {
				context.$store.commit('activeLoadingCredentials', {showLoading: false})
				return;
			}

			
			setTimeout(() => {
				context.$store.commit('activeLoadingCredentials', {showLoading: false})
			}, 5000);

			return true;
		} else {
			context.$store.commit('activeAlert', {
				message: 'A organização que você está tentando acessar não existe ou você não possui acesso.',
				status: 'error',
			});
			return;
		}
	} else if (organizationId == storedOrganizationId && workspaceId !== storedWorkspaceId) {
		if (uuidv4Regex.test(workspaceId)) {
			context.$store.commit('activeLoadingCredentials', {showLoading: true})

			const validate = await validateWorkspace(context, storedOrganizationId, workspaceId);
	
			setTimeout(() => {
				context.$store.commit('activeLoadingCredentials', {showLoading: false})
			}, 5000);
	
			if (validate) {
				return true;
			}
	
			context.$store.commit('activeAlert', {
				message: 'O espaço de trabalho que você está tentando acessar não existe ou você não possui acesso.',
				status: 'error',
			});
			return;
			

		} else {
			context.$store.commit('activeAlert', {
				message: 'O espaço de trabalho que você está tentando acessar não existe ou você não possui acesso.',
				status: 'error',
			});

			return;
		}
		
	}

	

}